// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import * as IMG_CONST from "./assets";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Color } from "@material-ui/lab/Alert";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start

interface InvalidResponseType {
  error: string;
};

export interface OtherService {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    logo: string | null | undefined;
  }
}

export interface OtherServiceData {
  data: OtherService[];
}

export interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  }
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  students: UserDetails;
  message: string;
  data: UserDetails;
  meta: {
    message: string;
  };
}

export interface SelectEventType {
    target: {
        value: string | unknown;
    }
  };

export interface RowsValueResponse {
  data: RowsValueResponse[];
  id: string;
  type: string;
  attributes: {
    request_id: string;
    status: string;
    date: string;
    time: string;
    student_name: string;
    service: string;
    logo: string;
  };
}

export interface StudentResponse{
  students: StudentResponse[];
  student_id: string;
  name: string;
  passport_number: string;
}
export interface SuccessMessageResponse {
  request_id: string;
  message: string;
}
// Customizable Area End

interface S {
    // Customizable Area Start
  toggleDrawer: boolean;
  userDetails: UserDetails;
  isStudentModal: boolean;
  studentValue: string | unknown;
  studentError: string;
  modalTitle: string;
  modalImg: string;
  otherServiceIdValue: string;
  studentIdValue: string;
  studentId: string;
  orderBy: string;
  order: "asc" | "desc";
  serviceData: Array<RowsValueResponse>;
  page: number;
  rowsPerPage: number;
  serviceRequestValue: string | unknown;
  valueKey: string;
  isSuccess: boolean;
  studentData: Array<StudentResponse>;
  otherService: Array<OtherService>;
  successMessage:SuccessMessageResponse;
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardOtherServiceController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  getProfileAPICallId: string = "";
  getTableAPICallId: string = "";
  getStudentAPICallId: string = "";
  otherServiceCallId: string = "";
  openAccountCallId: string = "";
  sortSerivceCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
      this.state = {
        toggleDrawer: false,
        userDetails: {} as UserDetails,
        isStudentModal: false,
        studentValue: "none",
        studentError:"",
        modalTitle: "",
        modalImg: "",
        otherServiceIdValue: "",
        studentIdValue:"",
        studentId: "",
        orderBy: "",
        order: "asc",
        valueKey: "",
        isSuccess: false,
        serviceData: [ ],
        page: 1,
        rowsPerPage: 10,
        serviceRequestValue: "none",
        studentData:[],
        otherService: [],
        successMessage: {} as SuccessMessageResponse,
        isAlert: false,
        alertMsg: "",
        alertType: "success"
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
        }
        if (responseJson.error) {
          this.apiFailureCall(apiRequestCallId, responseJson);
        }
      }
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
      this.getFormProfileAccount();
      this.getStudentApi();
      this.getTableRecord();
      this.getOtherServiceApi();
      window.scroll({
        top: 0,
        left: 0
      })
    }
    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };
    
    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    navigationToAnyPage = (pageName: string) => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            pageName
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    openCloseStudentModal = (title: string = "",imgTitle: string | null | undefined = "",idvalue: string = "",) => {
      this.setState((prevState) => ({
        studentValue: "none",
        studentError: "",
        isStudentModal: !prevState.isStudentModal,
        modalTitle: title,
        modalImg: imgTitle || prevState.modalImg,
        otherServiceIdValue: idvalue || prevState.otherServiceIdValue,
      }));
    };

    studentValueChange = (event: SelectEventType) => {
    this.setState({ studentValue: event.target.value });
  };

  serviceRequestChange = (event: SelectEventType) => {
    this.setState({ serviceRequestValue: event.target.value }, () => {
      this.getSortServiceApi();
    });
  };

  sortData = (data: RowsValueResponse[], orderBy: string, order: string) => {
    return [...data].sort((sortingA, sortingB) => {
      const aValue = sortingA.attributes[orderBy as keyof typeof sortingA.attributes];
      const bValue = sortingB.attributes[orderBy as keyof typeof sortingB.attributes];
  
      if (typeof aValue === "string" && typeof bValue === "string") {
        return order === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return order === "asc" ? aValue - bValue : bValue - aValue;
      }
      return 0;
    });
  }

  renderResultText = () => {
    const { serviceData, orderBy, order } = this.state;
    const startIndex = (this.state.page - 1) * this.state.rowsPerPage;
    const endIndex = startIndex + this.state.rowsPerPage;
    const sortedData = this.sortData(serviceData, orderBy, order);
    return configJSON.showingResult + " " + (startIndex + 1) + " " + "to" + " " + Math.min(endIndex, sortedData.length) + " " + "of " + " " + sortedData.length
  };

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: "asc" | "desc" = "asc";
    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }
    this.setState({ orderBy, order });
  };

  paginateData = (data: RowsValueResponse[], page: number, rowsPerPage: number) => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  renderCount = () => {
    const { serviceData, orderBy, order } = this.state;
    const sortedData = this.sortData(serviceData, orderBy, order);
    return Math.ceil(sortedData.length / this.state.rowsPerPage)
  };

  handleChangePage = (event: { type: string }, newPage: number) => {
    this.setState({ page: newPage, valueKey:event?.type });
  };

  openCloseSuccssModal = () => {
    this.setState({
      isSuccess: !this.state.isSuccess
    });
  };

  onOpenAccountClick = () => {
    this.state.studentValue === "none" ?
      this.setState({
        studentError: "Please select a student"
      }) :
    this.postOpenAccountApi();
  }

  navigateToNewStudentPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "FormApprovalWorkflow"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };


  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType & UserDetailsResponse & RowsValueResponse & StudentResponse & OtherServiceData & SuccessMessageResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.profilePicSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.getTableAPICallId) {
      this.tableSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.getStudentAPICallId) {
      this.studentSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.otherServiceCallId) {
      this.otherServiceSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.openAccountCallId) {
      this.openAccountSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.sortSerivceCallId) {
      this.serviceSucessCallBack(responseJson);
    }
  };


  apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType ) => {
    if (apiRequestCallId === this.openAccountCallId) {
        this.openAccountFailureCallBack(responseJson);
    }
  };

  openAccountFailureCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      studentError: responseJson.error
    })
  };
 
  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data) || responseJson.message || responseJson.students
  };

  profilePicSucessCallBack = (responseJson:UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  tableSucessCallBack = (responseJson: RowsValueResponse) => {
    this.setState({ serviceData: responseJson.data });
  };
  
  serviceSucessCallBack = (responseJson: RowsValueResponse) => {
    this.setState({ serviceData: responseJson.data });
  };

  studentSucessCallBack = (responseJson: StudentResponse) => {
    this.setState({ studentData: responseJson.students });
   };

   otherServiceSucessCallBack = (responseJson:OtherServiceData)=>{
    this.setState({ otherService: responseJson.data});
  };

  openAccountSucessCallBack = (responseJson: SuccessMessageResponse) => {
    this.setState({
      isStudentModal: !this.state.isStudentModal,
      isSuccess: !this.state.isSuccess,
      successMessage: responseJson
    })
  };

  getFormProfileAccount = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  };

  getTableRecord = async () => {
    this.getTableAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getTableEndPoint 
    });
  };

  getStudentApi = async () => {
    this.getStudentAPICallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getStudentEndPoint 
    });
  };

  getOtherServiceApi = async () => {
    this.otherServiceCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getOtherServiceEndPoint
    });
  };

  getSortServiceApi = async () => {
    this.sortSerivceCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: `${configJSON.sortByEndpoint}?query=${this.state.serviceRequestValue}`
    });
  };

  postOpenAccountApi = async () => {
    const formdata = new FormData();
    formdata.append("student_id", this.state.studentValue as string);
    formdata.append("other_service_id", this.state.otherServiceIdValue);
    this.openAccountCallId = await this.apiCall({
      method: configJSON.postApiMethod,
      endPoint: configJSON.openAccountEndpoint,
      body: formdata,
      type: configJSON.formData
    });
  };

  statusClass = (item: RowsValueResponse) => {
    const statusClassMap: { [key: string]: string } = {
      APPROVED: "approvedClass",
      PENDING: "pendingClass",
      REJECTED: "rejectedClass",
    };
    return statusClassMap[item.attributes.status] || "";
  };

  requestIdTxt = () => {
    return this.state.successMessage?.request_id?.split("Your request id is ")[1]
  };
    // Customizable Area End
}