import React from "react";
// Customizable Area Start
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import RequestManagementTableController, {
    Props
} from "./RequestManagementTableController.web";
export const configJSON = require("./config");
import DateRangeIcon from '@material-ui/icons/DateRange';
import { icolors } from "./RequestManagementTableController.web";

import {
    Box,
    Button,
    Checkbox,
    Typography,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Grid
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
// Customizable Area End

export default class RequestManagementTable extends RequestManagementTableController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCell = (params: 'ACCEPTED' | 'CANCELLED' | 'PROCESSING' | 'READY TO ENROLL' | 'NOT PAID' | 'REFUND IN PROGRESS') => {
        const colors : icolors = {'ACCEPTED': "#059669", 'CANCELLED' : "#DC2626", 'PROCESSING' : "#D97706", 'READY TO ENROLL' : "#059669", 'NOT PAID' : "#DC2626", 'REFUND IN PROGRESS' : "#D97706"}
        const backgroundColor : icolors = {'ACCEPTED':"D1FAE5", 'CANCELLED' : "#FEE2E2", 'PROCESSING' : "#FEF3C7", 'READY TO ENROLL' : "D1FAE5", 'NOT PAID' : "#FEE2E2", 'REFUND IN PROGRESS' : "#FEF3C7"}
        return (
            <div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <TableCellTypographyColumnContentStatus style={{ color:colors[params], backgroundColor:backgroundColor[params]}}>{params}</TableCellTypographyColumnContentStatus>
                </div>
            </div>
        )
     }

     renderCell2 = (params: string) => {
        return (
         <div>
             <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
             <RenderCell2Typography >{params}</RenderCell2Typography>
             </div>
         </div>
        )
    }

      studentMonitorTable = () => {
        const createData = (name: string, totalApplicants: number, submitted: number, loa: number, paidTutionfee: number,
           loaPending: number) => {
           return { name, totalApplicants, submitted, loa, paidTutionfee,loaPending };
         }
     
         return (
          <>
           <TableContainer style={{backgroundColor:"white"}} >
           <Table aria-label="simple table" style={{width:"auto"}}>
             <TableHead>
               <TableRow >
               <TableCell><Checkbox /></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>Action</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>First Name</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>Last Name</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>App ID</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>Student ID</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographySmall>Passport No</TableCellTypographySmall></TableCell>
                 <TableCell  align="center"><TableCellTypographyLarge>Program</TableCellTypographyLarge></TableCell>
                 <TableCell  align="center"><TableCellTypographyLarge>School</TableCellTypographyLarge></TableCell>
                 <TableCell  align="center"><TableCellTypographyLarge>Recruitment Partner</TableCellTypographyLarge></TableCell>
                 <TableCell  align="center"><TableCellTypographyMedium >Payment Date <DateRangeIcon /></TableCellTypographyMedium></TableCell>
                 <TableCell  align="center"><TableCellTypographyMedium>ApplyDate<DateRangeIcon /></TableCellTypographyMedium></TableCell>
                 <TableCell  align="center"><TableCellTypographyMedium>Start Date<DateRangeIcon /></TableCellTypographyMedium></TableCell>
                 <TableCell  align="center"><TableCellTypographyMedium>Status<ExpandMoreIcon /></TableCellTypographyMedium></TableCell>
                 <TableCell  align="center"><TableCellTypographyMedium>Current Stage<ExpandMoreIcon /></TableCellTypographyMedium></TableCell>
               </TableRow>
             </TableHead>
             <TableBody>
               {this.rows.slice(this.state.page * 10, this.state.page * 10 + 10).map((row,index) => (
                 <TableRow key={index}>
                    <TableCell align="center"><Checkbox /></TableCell>
                    <TableCell style={{width:"160px", color: '#059669',}} align="center">{(row.action)}</TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{(row.firstName)}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center" component="th" scope="row"><TableCellTypographyColumnContent>{row.lastName}</TableCellTypographyColumnContent></TableCell>
                   <TableCell><TableCellGreenBoldText  align="center">{row.appId}</TableCellGreenBoldText ></TableCell>
                   <TableCell><TableCellGreenBoldText  align="center">{row.studentId}</TableCellGreenBoldText></TableCell>
                   <TableCell><TableCellGreenBoldText  align="center">{row.passportNo}</TableCellGreenBoldText></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.program}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.school}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.recruitmentPartner}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.paymentDate}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.applyDate}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center"><TableCellTypographyColumnContent>{row.startDate}</TableCellTypographyColumnContent></TableCell>
                   <TableCell align="center">{this.renderCell(row.status)}</TableCell>
                   <TableCell align="center">{this.renderCell2(row.currentStage)}</TableCell>

                 </TableRow>
               ))}
             </TableBody>
           </Table>
         </TableContainer>
         <Box display={'flex'} justifyContent={"center"} alignItems={"center"} bgcolor="white">
         <CustomizeTablePagination
         data-test-id = 'tablePaginationID'
          count={this.rows.length}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
          labelRowsPerPage=''
          page={this.state.page}
          onPageChange={this.handleChangePage}
        />
        </Box>
         </>
         )
       }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <Box>
            <AppHeader isLogin={false}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={true}
          userName={undefined}
          role={undefined}
        />
            <UserProfileBox>
          <SideMenuBar data-test-id="sidebarNavigationBtn"
             navigationToAnyPage={this.navigationToAnyPage} activeComponent="RequestManagementTableWeb"/>
              <Box>
                <Box className="innerBox">
                    <BigBox>
                        <Box>
                            <Typography style={{fontWeight: 700, fontSize:"22px", fontFamily: "Plus Jakarta Sans", marginLeft:"8px"}}>Applications</Typography>
                        </Box>
                        <BoxButton >
                            <Button
                                style={{
                                    backgroundColor: "#00D794",
                                    color: "white", width: "224px",
                                    height: "44px",
                                    padding: "10px 16px",
                                    gap: "8px",
                                    borderRadius: "8px",
                                    marginRight: "8px",
                                    textTransform: "none",
                                }}>
                                <Typography style={{textTransform: "none",fontWeight: 600, fontSize:"12px"}}>Assign Task</Typography>
                            </Button>
                            <Button style={{
                                backgroundColor: "#00D794",
                                color: "white", width: "224px",
                                height: "44px",
                                padding: "0px 16px",
                                borderRadius: "8px",
                                marginRight: "8px",
                                textTransform: "none",                           
                            }}>
                                <Typography style={{textTransform: "none", fontWeight:600,fontSize:"12px"}}>Add Student Application</Typography>
                            </Button>
                            <Button style={{
                                width: "190px",
                                height: "44px",
                                padding: "10px 16px",
                                gap: "8px",
                                borderRadius: "8px",
                                backgroundColor: "#373335",
                                color:"white",
                                textTransform: "none",
                               
                            }}>
                                <FilterListIcon style={{marginRight: "7px"}}/>
                                <Typography style={{textTransform: "none",fontWeight: 600, fontSize:"12px"}}>Filter</Typography>
                            </Button>
                        </BoxButton>
                    </BigBox>
                    <div style={{marginTop: "40px"}}>
                        <div style={{ height:"100%", width: '100%', marginBottom:"40px" }}> 
                            {
                                this.studentMonitorTable()
                            }
                        </div>
                    </div>
                </Box>

              </Box>
            </UserProfileBox>
          </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const UserProfileBox = styled(Box)({
  "& .innerBox":{
    display: "flex", 
    backgroundColor: "#F7F7F7", 
    flexDirection: "column", 
    padding: "0PX 40px",
    marginLeft: "64px",
    justifyContent: "space-between", 
    fontFamily: "Plus Jakarta Sans", 
  }
});

const BigBox = styled(Box)({
    "@media (max-width:960px)": {
        display:'flex', flexDirection:"column",  justifyContent:'center', alignItems:'center', marginTop:'8px', marginBottom:'8px'
      },
      "@media (min-width:720px)": {
        display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'20px', marginBottom:'20px'
      }
})

const BoxButton = styled(Box)({
    "@media (max-width:960px)": {
        display:'flex', flexDirection:"column",  justifyContent:'space-between', height:"170px", alignItems:'center', marginTop:'20px', marginBottom:'20px'
      },
      "@media (min-width:720px)": {
        display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:'20px', marginBottom:'20px'
      }
})

const TableCellTypographySmall = styled(Typography) ({
  width:"160px", fontWeight:700, fontSize:"14px", display:"flex", justifyContent:"center", alignItems:"center", fontFamily: "Plus Jakarta Sans"
})
const TableCellTypographyMedium = styled(Typography) ({
  width:"200px", fontWeight:700, fontSize:"14px", display:"flex", justifyContent:"center", alignItems:"center", fontFamily: "Plus Jakarta Sans"
})
const TableCellTypographyLarge = styled(Typography) ({
  width:"300px", fontWeight:700, fontSize:"14px", display:"flex", justifyContent:"center", alignItems:"center", fontFamily: "Plus Jakarta Sans"
})
const TableCellTypographyColumnContent = styled(Typography) ({
  fontSize:"14px", display:"flex", justifyContent:"center", alignItems:"center", fontFamily: "Plus Jakarta Sans"
})
const TableCellTypographyColumnContentStatus = styled(Typography) ({
  color: '#059669', fontSize:"12px", display:"flex", justifyContent:"center", fontFamily: "Plus Jakarta Sans", alignItems:"center",padding:"2px 8px 2px 8px", borderRadius:"20px", fontWeight:700
})
const RenderCell2Typography = styled(Typography) ({
  backgroundColor:"#CBD5E1", padding:"2px 8px 2px 8px", borderRadius:"20px", fontFamily: "Plus Jakarta Sans", fontSize:"12px", display:"flex", justifyContent:"center", alignItems:"center",
})
const TableCellGreenBoldText = styled(Typography) ({
  color: '#059669', fontWeight:700, fontSize:"14px", fontFamily: "Plus Jakarta Sans",
})

const CustomizeTablePagination = styled(TablePagination) ({
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
})
const webStyle={
  backgroundColor: {
    background:"#F7F7F7" 
   },
}
// Customizable Area End