import React from "react";
// Customizable Area Start
import ForgotPasswordController, { Props, configJSON } from "./ForgotPasswordController.web";
import { Box, Button, Snackbar, TextField, Typography, styled } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AppHeader from "../../../components/src/AppHeader.web";
import * as IMG_CONST from "./assets";
// Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderForgotPswrd = () => {
        return (
            <MainBoxForgotPswrd>
                <FirstBox>
                    <ForgotPswrdTxt
                        data-test-id="forgotPswrdTxtTestId"
                    >
                        {configJSON.forgotPswrdTxt}
                    </ForgotPswrdTxt>
                    <ForgotPsrwdInnerBox>
                        <InputMainFrgtPswrdBox>
                            <InputTxt>
                                {configJSON.pswrdTxt}
                                <StarTxt> {configJSON.starTxt}</StarTxt>
                            </InputTxt>
                            <InputField
                                data-test-id="pswrdChangeTestId"
                                placeholder={configJSON.pswrdTxtPlaceHolder}
                                onChange={this.handlePswrdChange}
                                value={this.state.pswrdValue}
                            />
                            {this.state.pswrdError && (
                                <RequiredTxt>{this.state.pswrdErrorTxt}</RequiredTxt>
                            )}
                        </InputMainFrgtPswrdBox>
                        <InputMainFrgtPswrdBox>
                            <InputTxt>
                                {configJSON.confrimPswrdTxt}
                                <StarTxt> {configJSON.starTxt}</StarTxt>
                            </InputTxt>
                            <InputField
                                placeholder={configJSON.confrimPswrdTxt}
                                onChange={this.handleConfirmPswrdChange}
                                value={this.state.confrimPswrdValue}
                                data-test-id="confirmPswrdChangeTestId"
                            />
                            {this.state.confrimPswrdError && (
                                <RequiredTxt >{this.state.confrimPswrdErrorTxt}</RequiredTxt>
                            )}
                        </InputMainFrgtPswrdBox>
                        <SaveButton
                            data-test-id="signUpBtnTestId"
                            onClick={this.onForgotPswrdClick}
                        >
                            {configJSON.forgotBtnTxt}
                        </SaveButton>
                    </ForgotPsrwdInnerBox>
                    <BackBtnBox >
                        <BackButton
                            data-test-id="LoginPageTestId"
                            onClick={this.navigateToLoginPage}
                        >
                            {configJSON.backToLoginTxt}
                        </BackButton>
                    </BackBtnBox>
                </FirstBox>
                <SecondBox>
                    <SideImg src={IMG_CONST.sideImgSetPswrd} />
                </SecondBox>
            </MainBoxForgotPswrd>
        );
    };

    renderSuccess = () => {
        return (
            <MainBoxForgotPswrd>
                <SuccessFirstBox>
                    <SuccessImg src={IMG_CONST.successImgSetPswrd} />
                    <SuccessTxt data-test-id="successfullTxtTestId">
                        {configJSON.successfullyTxt}
                    </SuccessTxt>
                    <CreatePswrdTxt>
                        {configJSON.forgotPswrd}
                    </CreatePswrdTxt>
                    <ContinueBtn onClick={this.navigateToLoginPage}>{configJSON.continueBtnTxt}</ContinueBtn>
                </SuccessFirstBox>
                <SecondBox>
                    <SideImg src={IMG_CONST.sideImgSuccessSetPswrd} />
                </SecondBox>
            </MainBoxForgotPswrd>
        );
    };

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    onClose={this.oncloseAlert}
                    data-test-id="alertTestId"
                >
                    <Alert data-test-id="alertTestMsgId" severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
                <AppHeader
                    role={undefined}
                    userName={undefined}
                    isUserLogin={false}
                    isLogin={false}
                    onHomeClick={this.onHomeClick}
                    onLoginClick={undefined}
                    onSignUpClick={undefined}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    toggleData={this.state.toggleDrawer} />
                {this.state.isSuccess && this.renderSuccess()}
                {this.state.isPswrd && this.renderForgotPswrd()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainBoxForgotPswrd = styled(Box)(({ theme }) => ({
    margin: "40px 0px", display: "flex",
    justifyContent: "space-around", gap: "20px",
    [theme.breakpoints.down("sm")]: {
        margin: "20px 20px"
    },
    [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse", alignItems: "center",
        margin: "10px"
    }
}));

const InputMainFrgtPswrdBox = styled(Box)({
    margin: "0px 0px 20px 0px"
});

const FirstBox = styled(Box)(({ theme }) => ({
    display: "flex", width: "400px", flexDirection: "column",
    justifyContent: "center", alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
        width: "100%"
    },
    [theme.breakpoints.down("xs")]: {
        padding: "20px"
    },
}));

const StarTxt = styled("span")({
    fontFamily: "Plus Jakarta Sans",
    color: "red"
});

const InputTxt = styled(Typography)({
    color: "#334155", fontSize: "14px",
    fontWeight: 600, fontFamily: "Plus Jakarta Sans"
});

const InputField = styled(TextField)({
    width: "100%", border: "1px solid #CBD5E1",
    borderRadius: "8px", height: "50px",
    margin: "5px 0px", display: "flex",
    justifyContent: "center", padding: "0px 10px", boxSizing: "border-box",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none"
        },
        "&::before": {
            content: "none"
        },
        "&::after": {
            content: "none"
        }
    }
});

const RequiredTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
});

const SaveButton = styled(Button)({
    width: "100%", backgroundColor: "#BB393D",
    color: "#fff", fontSize: "14px",
    textTransform: "capitalize", fontFamily: "Plus Jakarta Sans",
    height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});

const BackBtnBox = styled(Box)({
    display: "flex", justifyContent: "center"
});

const BackButton = styled(Button)({
    width: "200px", color: "#0F172A",
    fontSize: "12px", textTransform: "unset",
    fontFamily: "Plus Jakarta Sans", height: "30px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#fff"
    }
});


const SideImg = styled("img")({
    height: "450px",
    width: "450px",
    "@media (max-width: 768px)": {
        height: "300px",
        width: "300px"
    },
    "@media (max-width: 600px)": {
        height: "200px",
        width: "200px"
    }
});

const SecondBox = styled(Box)({
    display: "flex", alignItems: "center", flexDirection: "column"
});

const ContinueBtn = styled(Button)({
    width: "80%", backgroundColor: "#BB393D",
    color: "#fff", fontSize: "14px",
    textTransform: "capitalize", fontFamily: "Plus Jakarta Sans", height: "50px",
    "&.MuiButton-root:hover": {
        backgroundColor: "#BB393D"
    }
});

const SuccessFirstBox = styled(Box)({
    margin: "20px 0px", width: "430px",
    display: "flex", flexDirection: "column",
    justifyContent: "center", alignItems: "center",
    "@media (max-width: 600px)": {}
});

const ForgotPswrdTxt = styled(Typography)({
    color: "#B6373C", fontSize: "20px",
    fontWeight: 700, fontFamily: "Plus Jakarta Sans",
    textAlign: "center", margin: "0px 0px 5px 0px",
    "@media (max-width: 600px)": { fontSize: "16px" }
});

const ForgotPsrwdInnerBox = styled(Box)({
    padding: "30px 0px",
    "@media (max-width: 600px)": {}
});

const SuccessTxt = styled(Typography)({
    color: "#000", fontWeight: 500,
    fontSize: "35px", fontFamily: "Plus Jakarta Sans",
    margin: "0px 0px 15px 0px", lineHeight: 1,
    padding: "30px 0px",
    "@media (max-width: 600px)": { fontSize: "28px" }
});

const CreatePswrdTxt = styled(Typography)({
    color: "#858585", fontWeight: 400,
    fontSize: "18px", fontFamily: "Plus Jakarta Sans",
    margin: "0px 0px 15px 0px",
    "@media (max-width: 600px)": { fontSize: "14px" }
});

const SuccessImg = styled("img")({
    height: "200px",
    width: "200px",
    "@media (max-width: 600px)": {}
});
const MainBoxEmail = styled(Box)({
    justifyContent: "space-around", display: "flex",
    margin: "40px 0px",
    "@media (max-width: 600px)": {}
});
// Customizable Area End