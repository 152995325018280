import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconFourteen extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M26 15.5C26 21.02 21.52 25.5 16 25.5C10.48 25.5 6 21.02 6 15.5C6 9.98 10.48 5.5 16 5.5C21.52 5.5 26 9.98 26 15.5Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.7089 18.68L16.6089 16.83C16.0689 16.51 15.6289 15.74 15.6289 15.11V11.01" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }
}



