Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.firstnameTxt = "First Name";
exports.lastnameTxt = "Last Name";
exports.firstnameTxtPlaceholder = "Enter your first name";
exports.lastnameTxtPlaceholder = "Enter your last name";
exports.emailTxt = "Email";
exports.emailTxtPlaceholder = "Enter your email";
exports.starTxt = "*";
exports.contactTxt = "Contact with us";
exports.touchTxt = "Please get in touch with us using the form below. We're looking to get back to you as soon as possible!";
exports.contactNumberTxt = "Contact Number";
exports.contactNumberTxtPlaceholder = "Enter contact number";
exports.whichCountryTxt = "Which country you are from?";
exports.cityText = "City";
exports.cityPlaceHoderText = "Enter city name";
exports.messageText = "Message";
exports.messageTextPlaceholder = "Write a message here";
exports.submitBtnTxt = "Submit";
exports.globalTxt = " Our Global Presence";
exports.selectCountry = "Select Country";
exports.ThanksTxt = "Thank You for Reaching Out!";
exports.AppreciateTxt = "We appreciate you taking the time to contact us.Your details have been successfully submitted, and our team will review your inquiry shortly!";
exports.selectCountryPlaceholderTxt = "Select country";
exports.starTxt = "*";
exports.formAPIEndPoint = "bx_block_contact_us/contacts";
exports.formDatatype = "formData";
// Customizable Area End