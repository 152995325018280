// Customizable Area Start
export const History = require("../assets/History.png");
export const Close = require("../assets/Close.png");
export const location = require("../assets/location.png");
export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const imgsplash = require("../assets/splash.png");
export const imgambarsar = require("../assets/ambarsar.png");
export const imgsurat = require("../assets/surat.png");
export const Maplogo = require("../assets/Map.png");
export const Plus = require("../assets/Plus.png");
export const startpin = require("../assets/Start_pin.png");
export const destinationPin = require("../assets/destination_pin.png");
export const Flag = require("../assets/Flag.png");
export const DragAndDrop = require("../assets/DRAGANDDROP.svg")
export const successTick = require("../assets/group_tick.png");

// Customizable Area End
