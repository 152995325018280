import React from "react";

import {
  Box,
  Button,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TextField,
  MenuItem
  // Customizable Area Start
  ,Typography,
  styled,
  Divider,
  AppBar,
  StepConnector,
  StepIconProps,
  withStyles,
  Tab as TabNew
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import * as Yup from 'yup';
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Tabs from "@material-ui/core/Tabs";
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import * as IMG_CONST from "./assets";

import MultipageFormsController, {
  Props,
  Gender,
  Country,
  Industry,
  configJSON,
} from "./MultipageFormsController";
import { Formik } from "formik";

// Customizable Area End


export default class MultipageForms extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  renderTab = () => {
    return (
      <MainTabBox>
        <div >
          <AppBar position="static">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              data-test-id="tabChangeTestId"
            >
              <TabNew className="tabLabel" 
              label="Applicant Requirements (2)" value={0} data-test-id="bankInfoTestId" />
              <TabNew className="tabLabel" label="Institute Documents (3)" value={1} />
              <TabNew className="tabLabel" label="Conversation (2)" 
              value={2} />
              <TabNew 
              className="tabLabel" label="Eligiblity(2)" value={3} />
              <TabNew className="tabLabel" 
              label="Purchase Services(2)" value={4} />
            </Tabs >
          </AppBar >
        </div >
      </MainTabBox >
    );
  };

  renderTabCustom = () => {
    return (
      <MainTabBox2>
        <div >
          <AppBar position="static">
            <Tabs
              value={this.state.valueCustom}
              onChange={this.handleChangeCustom}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              data-test-id="tabChangeTestIdCustom"
            >
              <TabNew 
              className="tabLabel" label="Student details" value={0} />
              <TabNew className="tabLabel" label="Assigned Staffs" value={1} 
              data-test-id="bankInfoTestIdCustom" />
              <TabNew className="tabLabel" label="Course Information" 
              value={2} />
            </Tabs >
          </AppBar >
        </div >
      </MainTabBox2 >
    );
  };

  qontoStepIcon = (props: StepIconProps) => {
    const { completed } = props;
  
    return (
      <div
        style={webstyles2.root}
      >
        {completed ? (
          <CheckCircleIcon style={webstyles2.completed} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon style={{color:"#34D399"}}/>
        )}
      </div>
    );
  }

  stepperPanel = () => {
    return (
      <Box>
      <StepperStyled
        alternativeLabel
        activeStep={this.state.activeStepsNew}
        connector={<QontoConnector/>}
      >
        {this.getStepsNew().map((label, index) => (
          <Step key={label} >
            <StepLabel StepIconComponent={this.qontoStepIcon}>
              <StepperTypography 
              style={{color:this.state.activeStepsNew - 1 >= index ? "#059669" : "black"
              }}>{label}</StepperTypography></StepLabel>
          </Step>
        ))}
      </StepperStyled>
      <div>
        {this.state.activeStepsNew === this.getStepsNew().length ? (
          <div>
            <p>All steps completed</p>
          </div>
          ) : (
            <div>                
            </div>
          )
        }
      </div>
    </Box>
    )
  }


  render() {
  // Customizable Area End
  
    return (
      // Customizable Area Start
      <>
        <div style={{ width: "100%" }}>
        <AppHeader isLogin={false}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={true}
          userName={undefined}
          role={undefined}
        />
        <MainBox>
          <FirstBox>
            <TypographyApplications>{configJSON.applicationsText}</TypographyApplications>
          </FirstBox>
          <SecondBox>
            <SecondBox1>
              <Box style={webStyle.flexStyle}>
                <DPBox>
                <img
                style={webStyle.DpIcon}
                src={IMG_CONST.dpIcon}
                alt="Preview" />
                </DPBox>  
                <Box>
                  <Box style={{display:"flex", flexDirection:"column"}}>
                    <ButtonBox>
                      <SecondBoxNameTypography>{configJSON.nameText}</SecondBoxNameTypography>
                      <SecondBoxButtonColorStyle style={{borderColor:"#E342B9", backgroundColor:"#FFE8F9", color:"#E448BB"}}>{configJSON.studentIdText}</SecondBoxButtonColorStyle>
                      <SecondBoxButtonColorStyle style={{borderColor:"#1D85CE", backgroundColor:"#D8EFFF", color:"#1D85CE"}}>{configJSON.applicationIdText}</SecondBoxButtonColorStyle>
                      <SecondBoxButtonColorStyle style={{borderColor:"#C18701", backgroundColor:"#FFF1D1", color:"#C18701"}}>{configJSON.passportNoText}</SecondBoxButtonColorStyle>
                      <SecondBoxButtonColorStyle style={{borderColor:"#6200EA", backgroundColor:"#EEE2FF", color:"#6200EA"}}>{configJSON.universityIdText}</SecondBoxButtonColorStyle>
                      <img style={{width:"30px", height:"30px", backgroundColor:"#F0F0F0", marginBottom:"4px", borderRadius:"5px", padding:"4px"}} src={IMG_CONST.folderIcon} alt="Preview" />
                    </ButtonBox>
                  </Box>
                  <Box style={{display:"flex", padding:"8px 32px"}}>
                  <img style={iconStyle} src={IMG_CONST.callIcon} alt="Preview"/>
                    <Typography style={webStyle.firstBoxTypography}>{configJSON.numberText}</Typography>
                    <DateRangeIcon style={{marginLeft:"20px"}}/>
                    <Typography style={webStyle.firstBoxTypography}>{configJSON.dateText}</Typography>
                  </Box>
                  <Box style={{display:"flex", padding:"4px 32px"}}>
                  <img style={iconStyle} src={IMG_CONST.emailIcon} alt="Preview"/>
                  <Typography style={webStyle.firstBoxTypography}>{configJSON.emailText}</Typography>
                  </Box>
              </Box>
              </Box> 
              <Box>
                <FirstBoxApplicationReceivedTypography>{configJSON.applicationReceivedText}</FirstBoxApplicationReceivedTypography>
              </Box>
            </SecondBox1>
            <SecondBoxButtonRange style={webStyle.flexStyle}>
                  <SecondBoxButtonRangeTypography>{configJSON.gicText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.forexText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.accommodationText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.forexTravelCardText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.travelInsuranceText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.simCardText}</SecondBoxButtonRangeTypography>
                  <SecondBoxButtonRangeTypography>{configJSON.applyLoanText}</SecondBoxButtonRangeTypography>
            </SecondBoxButtonRange>
            <SecondBoxDivider>
              <Divider style={webStyle.dividerStyle}></Divider>
              <Box sx={webStyle.secondBoxDividerBox}>
                <Typography style={webStyle.secondBoxDividerTypography1}>{configJSON.universityNameText}</Typography>
                <Typography style={webStyle.secondBoxDividerTypography2}>{configJSON.courseNameText}</Typography>
                <Typography style={webStyle.secondBoxDividerTypography2}><Typography style={webStyle.secondBoxDividerTypography1}>{configJSON.intakeText}</Typography>{configJSON.intakeDateText}</Typography>
              </Box>
            </SecondBoxDivider>
            <StepperUIBox>
              {this.stepperPanel()}
              <Divider style={webStyle.dividerStyle2}></Divider>
              {this.renderTabCustom()}
              <Box style={{marginLeft:"140px"}}>
                <div style={{display:"flex", marginTop:"80px"}}>
                  <Typography style={webStyle.studentInfoTypography}>{configJSON.studentsAddText}</Typography>
                  <Typography style={webStyle.studentInfoTypography2}>{configJSON.studentsAddValueText}</Typography>
                </div>
                <div style={{display:"flex", marginTop:"16px"}}>
                  <Typography style={webStyle.studentInfoTypography}>{configJSON.emergencyContactText}</Typography>
                  <Typography style={{fontWeight:400, fontSize:"14px", width:"200px"}}>{configJSON.nameExampleText} <br /> {configJSON.emailAndPhoneText}</Typography>
                </div>
                <div style={{display:"flex", marginTop:"16px"}}>
                  <Typography style={webStyle.studentInfoTypography}>{configJSON.nationalityText}</Typography>
                  <Typography style={webStyle.studentInfoTypography2}>{configJSON.nationalityValueText}</Typography>
                </div>
                <div style={{display:"flex", marginTop:"16px"}}>
                  <Typography style={{fontWeight:700, fontSize:"16px", width:"200px"}}>{configJSON.residenceText}</Typography>
                  <Typography style={webStyle.studentInfoTypography2}>{configJSON.residenceValueText}</Typography>
                </div>
              </Box>
              <Box sx={webStyle.flexBoxStyleInfo}>
                <Box style={webStyle.flexStyle}>
                  <InfoDetailsTypography>{configJSON.createdOnText}</InfoDetailsTypography>
                  <Typography style={webStyle.infoDetailsTypography2}>{configJSON.createdOnDateText}</Typography>
                  <InfoDetailsTypography style={{marginLeft:"2px", marginRight:"2px"}}>{configJSON.dividerText}</InfoDetailsTypography>
                  <InfoDetailsTypography>{configJSON.createdByText}</InfoDetailsTypography>
                  <Typography style={webStyle.secondBoxDividerTypography2}>{configJSON.createdByNameText}</Typography>
                </Box>
                <Box style={webStyle.flexStyle}>
                  <InfoDetailsTypography>{configJSON.lastActivityDateText}</InfoDetailsTypography>
                  <Typography style={webStyle.infoDetailsTypography2}>{configJSON.lastActivityDateValueText}</Typography>
                  <InfoDetailsTypography style={{marginLeft:"2px", marginRight:"2px"}}>{configJSON.dividerText}</InfoDetailsTypography>
                  <InfoDetailsTypography>{configJSON.agencyText}</InfoDetailsTypography>
                  <Typography style={webStyle.infoDetailsTypography2}>{configJSON.agencyValueText}</Typography>
                  <Box style={webStyle.flexStyle}>
                  <img style={webStyle.InfoOutlinedIconStyle} src={IMG_CONST.messageIcon} alt="Preview" />
                    <InfoOutlinedIcon style={{fontWeight:200, display:"flex", justifyContent:"center", alignItems:"center",backgroundColor:"#D6F6EB", color:"#27A47D", borderRadius:"50%", padding:"3px", width:"22px", height:"22px", marginLeft:"10px"}}/> 
                  </Box>
                </Box>
              </Box>
            </StepperUIBox>

          </SecondBox>
          <Box>
           {this.renderTab()}
          </Box>
          <FourthBox style={{backgroundColor:"white"}}>
            <FourthBoxTop>
              <FourthBoxTopText>
                <TypographyFourthBoxTopFirst>{configJSON.prePaymentText}</TypographyFourthBoxTopFirst>
                <TypographyFourthBoxTopSecond>{configJSON.lastReqText}</TypographyFourthBoxTopSecond>
              </FourthBoxTopText>
              <FourthBoxTopSecond>
                <FourthBoxTopSecondIcons1>
                  <TypographyFourthBoxTopSecond style={{marginRight:"4px"}}>{configJSON.zeroText}</TypographyFourthBoxTopSecond>
                  <InfoOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons1>
                <FourthBoxTopSecondIcons2>
                <TypographyFourthBoxTopSecond>{configJSON.zeroText}</TypographyFourthBoxTopSecond>
                  <HourglassEmptyOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons2>
                <FourthBoxTopSecondIcons3>
                <TypographyFourthBoxTopSecond style={{marginRight:"4px"}}>{configJSON.zeroText}</TypographyFourthBoxTopSecond>
                  <CancelOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons3>
                <FourthBoxTopSecondIcons4>
                <TypographyFourthBoxTopSecond style={{marginRight:"2px"}}>{configJSON.fourText}</TypographyFourthBoxTopSecond>
                  <CheckOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons4>
              </FourthBoxTopSecond>
            </FourthBoxTop>

            <FourthBoxBottom style={{backgroundColor:"white", padding:"0px 32px"}}>        
              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.backlogLimitText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.backlogSupportingText}</Typography>
                </Box>               
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>

              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.copyPassText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.copyPassSupportText}</Typography>
                </Box>               
                <Box>
                  <RequiredTypography>Required</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>

              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box 
                style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>Country Specific GPA</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>For Indian applicants residing in India:</Typography>
                </Box>               
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>

              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.eduBackText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.eduBackSupportText}</Typography>
                </Box>
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>

              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.testValidityText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.testValiditySupportText}</Typography>
                </Box>
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>
            </FourthBoxBottom>
          </FourthBox>
          <FourthBox>
            <FourthBoxTop>
              <FourthBoxTopText>
                <TypographyFourthBoxTopFirst>{configJSON.preSubmissionText}</TypographyFourthBoxTopFirst>
                <TypographyFourthBoxTopSecond>{configJSON.preSubmissionSupportText}</TypographyFourthBoxTopSecond>
              </FourthBoxTopText>
              <FourthBoxTopSecond>
                <FourthBoxTopSecondIcons1>
                  <TypographyFourthBoxTopSecond style={{marginRight:"4px"}}>
                    {configJSON.zeroText}
                    </TypographyFourthBoxTopSecond>
                  <InfoOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons1>
                <FourthBoxTopSecondIcons2>
                <TypographyFourthBoxTopSecond>
                  {configJSON.zeroText}
                  </TypographyFourthBoxTopSecond>
                  <HourglassEmptyOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons2>
                <FourthBoxTopSecondIcons3>
                <TypographyFourthBoxTopSecond 
                style={{marginRight:"4px"}}>
                  {configJSON.zeroText}
                  </TypographyFourthBoxTopSecond>
                  <CancelOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons3>
                <FourthBoxTopSecondIcons4>
                <TypographyFourthBoxTopSecond style={{marginRight:"2px"}}>
                  {configJSON.fourText}
                  </TypographyFourthBoxTopSecond>
                  <CheckOutlinedIcon style={webStyle.FourOutlinedIcons}/>
                </FourthBoxTopSecondIcons4>
              </FourthBoxTopSecond>
            </FourthBoxTop>

            <FourthBoxBottom>  
              <Box style={{marginBottom:"8px", backgroundColor:"white", paddingLeft:"56px", paddingRight:"56px"}}>      
              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.pteNameText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.pteNameSupportText}</Typography>
                </Box>               
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>
              </Box>
              <Box style={{backgroundColor:"white", paddingLeft:"56px", paddingRight:"56px"}}>      
              <FourthBoxBottomTiles>
                <CheckCircleOutlineOutlinedIcon style={webStyle.CheckCircleOutlineOutlinedIconStyle}/>
                <Box style={{width:"851.86px"}}>
                  <Typography style={webStyle.FourthBoxCardHeading}>{configJSON.backupProgText}</Typography>
                  <Typography style={webStyle.FourthBoxCardText}>{configJSON.backupProgSupportText}</Typography>
                </Box>               
                <Box>
                  <RequiredTypography>{configJSON.requiredText}</RequiredTypography>
                </Box>
              </FourthBoxBottomTiles>
              </Box>
            </FourthBoxBottom>
          </FourthBox>
        </MainBox>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainBox = styled(Box) ({
  display:"flex", 
  flexDirection:"column", 
  padding:"58px 41px", 
  backgroundColor:"#F7F7F7", 
  justifyContent:"center",
  alignItems: "center"
})
const FirstBox = styled(Box) ({
  display:"flex", 
  justifyContent:"start", 
  alignItems:"start",
  width:"1360px"
})
const TypographyApplications = styled(Typography) ({
  fontWeight:700, 
  fontSize:"22px", 
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  fontFamily: "Plus Jakarta Sans"
})
const FirstBoxApplicationReceivedTypography = styled(Typography) ({
  fontSize:"12px", 
  width:"191px", 
  height:"32px", 
  borderRadius:"5px", 
  border:"solid 1px", 
  borderColor:"#34D399", 
  backgroundColor:"#D6F6EB", 
  fontWeight:700, display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  color:"#34D399"
})
const SecondBox = styled(Typography) ({
  display:"flex", 
  flexDirection:"column",
  justifyContent:"start", 
  alignItems:"start",
  width:"1296px", 
  fontFamily: "Plus Jakarta Sans",
  backgroundColor:"white",
  padding:"40px 32px",
  marginTop:"48px"
})
const SecondBox1 = styled(Typography) ({
  display:"flex", 
  justifyContent:"space-between", 
  alignItems:"start", 
  fontFamily: "Plus Jakarta Sans",
  width:'100%',
})
const DPBox = styled(Box) ({
  width:"96px",
  height:"96px",
  borderRadius:"50%",
})
const ButtonBox = styled(Box) ({
  display:"flex",
  flexDirection:'row',
  justifyContent:"space-between",
  marginLeft:"36px",
})
const SecondBoxDivider = styled(Box) ({
  position:"relative", 
  width:"1264px",
  marginLeft:"10px"
})
const SecondBoxButtonRange = styled(Box) ({
  display:"flex",
  flexDirection:'row',
  justifyContent:"space-between",
  width:"1264px",
  marginTop:"20px"
})
const SecondBoxButtonRangeTypography = styled(Typography) ({
  fontWeight:700, 
  fontSize:"16px", 
  border:"solid 2px", 
  borderRadius:"8px", 
  padding:"10px 16px 10px 16px", 
  borderColor:"#BB393D", 
  color:"#BB393D", 
  fontFamily:"Plus Jakarta Sans"
})
const SecondBoxNameTypography = styled(Typography) ({
  fontFamily: "Plus Jakarta Sans", 
  margin:"0px 10px", 
  fontSize:"18px", 
  fontWeight:700, 
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center"
})
const SecondBoxButtonColorStyle = styled(Typography) ({
  fontFamily: "Plus Jakarta Sans", 
  margin:"0px 10px", 
  fontSize:"12px", 
  width:"140px", 
  height:"32px", 
  borderRadius:"5px", 
  border:"solid 1px", 
  fontWeight:500, 
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
})
const MainTabBox = styled(Box)({
  margin: "30px 0px 0px 0px",
  border:"1px solid",
  borderRadius:"50px",
  borderColor:"#34D399",
  width:"1195px",
  "& .MuiTabs-flexContainer": {
    display:"flex",
    justifyContent:"space-between",
    height:"48px"
  },
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff",
    borderRadius:"50px"
  },
  "& .MuiTab-wrapper": {
    color: "#000"
  },
  "& .MuiBox-root-18": { padding: "24px 0px" },

  "& .MuiTabs-indicator ": {
    backgroundColor: "transparent",
  },
  "& .Styled(MuiBox)-root-3 .MuiTab-wrapper ": {
    color: "blue !important",
    fontSize: "50px"
  },
  "& .MuiPaper-elevation4": {
    boxShadow: "none",
    borderBottom: "2px solid #E2E8F0"
  },
  "& MuiBox-root-18": {
    backgroundColor: "#fff"
  },
  "& .tabLabel": {
    textTransform: "unset",
    fontSize: "16px",
    fontWeight:700,
    fontFamily:"Plus Jakarta Sans"
  },
  "& .Mui-selected": {
    backgroundColor:"#D6F6EB",
    borderRadius:"50px",
    height:"40px",
    border:"solid 1px",
    borderColor:"#D6F6EB"
  }
});
const StepperUIBox = styled(Box) ({
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  width:"1196px", 
  flexDirection:"column", 
  marginTop:"60px"
})
const StepperStyled = styled(Stepper) ({
  width:"1250px",
  marginLeft:"45px"
})
const MainTabBox2 = styled(Box)({
  margin: "47px 0px 0px 50px",
  border:"1px solid",
  borderRadius:"50px",
  borderColor:"#34D399",
  width:"560px",
  "& .MuiTabs-flexContainer": {
    display:"flex",
    justifyContent:"space-between"
  },
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff",
    borderRadius:"50px"
  },
  "& .MuiTab-wrapper": {
    color: "#000"
  },
  "& .MuiBox-root-18": { padding: "24px 0px" },

  "& .MuiTabs-indicator ": {
    backgroundColor: "transparent",
  },
  "& .Styled(MuiBox)-root-3 .MuiTab-wrapper ": {
    color: "blue !important",
    fontSize: "50px"
  },
  "& .MuiPaper-elevation4": {
    boxShadow: "none",
    borderBottom: "2px solid #E2E8F0"
  },
  "& MuiBox-root-18": {
    backgroundColor: "#fff"
  },
  "& .tabLabel": {
    textTransform: "unset",
    fontSize: "16px",
    fontWeight:700,
    fontFamily:"Plus Jakarta Sans"
  },
  "& .Mui-selected": {
    backgroundColor:"#D6F6EB",
    borderRadius:"50px"
  }
});
const TabBox = styled(Box)({
  padding: "24px 0px"
})
const FourthBox = styled(Box) ({
  display:"flex", 
  flexDirection:"column", 
  justifyContent:"start", 
  alignItems:"center", 
  width:"1296px", 
  borderRadius:"8px 8px 0px 0px",
  marginTop:"41px"
})
const FourthBoxTop = styled(Box) ({
  display:"flex", 
  justifyContent:"space-between", 
  alignItems:"center", 
  border:"solid 1px",
  borderColor:"#E2E8F0",
  height:"82px",
  backgroundColor:"white",
  width:"1296px",
  padding:"0px 32px"
})
const FourthBoxTopText = styled(Box) ({
  display:"flex", 
  justifyContent:"space-between",  
  paddingLeft:"61px",
  paddingRight:"71px",
  flexDirection:"column"
})
const FourthBoxTopSecond = styled(Box) ({
  display:"flex", 
  justifyContent:"space-between", 
  alignItems:"center", 
  flexDirection:"row", 
  width:"320px",
  marginRight:"41px"
})
const FourthBoxTopSecondIcons1 = styled(Box) ({
  display:"flex", 
  alignItems:"center", 
  flexDirection:"row", 
  color:"#C18701"
})
const FourthBoxTopSecondIcons2 = styled(Box) ({
  display:"flex", 
  alignItems:"center", 
  flexDirection:"row", 
  color:"#64748B"
})
const FourthBoxTopSecondIcons3 = styled(Box) ({
  display:"flex", 
  alignItems:"center", 
  flexDirection:"row", 
  color:"#DC2626"
})
const FourthBoxTopSecondIcons4 = styled(Box) ({
  display:"flex", 
  alignItems:"center", 
  flexDirection:"row", 
  color:"#34D399"
})
const FourthBoxBottom = styled(Box) ({
  display:"flex", 
  justifyContent:"start", 
  alignItems:"center", 
  paddingLeft:"51px", 
  paddingRight:"51px", 
  flexDirection:"column", 
  fontFamily:"Plus Jakarta Sans",
  width:"1296px",
  padding:"0px 42px"
})
const FourthBoxBottomTiles = styled(Box) ({
  display:"flex", 
  justifyContent:"space-between", 
  alignItems:"center", 
  height:"160px", 
  width:"1184px",
  borderRadius:"8px",
  padding:"0px 32px"
})
const RequiredTypography = styled(Box) ({
  width:"140.14px", 
  borderRadius:"5px", 
  backgroundColor:"#FFF1D1", 
  borderColor:"#C18701", 
  border:"solid 1px", 
  display:"flex", 
  justifyContent:"center", 
  color:"#C18701", 
  height:"32px", 
  alignItems:"center"
})
const TypographyFourthBoxTopFirst = styled(Typography) ({
  fontWeight:700, 
  fontSize:"24px", 
  display:"flex", 
  justifyContent:"start",  
  fontFamily: "Plus Jakarta Sans"
})
const TypographyFourthBoxTopSecond = styled(Typography) ({
  fontWeight:400, 
  fontSize:"14px", 
  display:"flex", 
  justifyContent:"start", 
  alignItems:"center", 
  fontFamily: "Plus Jakarta Sans", 

})
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  boxStyle: {
    maxWidth: '620px',
    margin: '0 auto',
},
successMsg:{
  color : 'red',
  display:'flex',
  justifyContent:'center',
},
errorMsg:{
  color:'red',
  fontSize:'14px',
  paddingTop:'5px',
},
firstBoxTypography: {
  fontSize:"16px",
  marginLeft:"10px",
  fontFamily: "Plus Jakarta Sans",
  dispay:"flex",
  justifyContent:"center",
  alignItems:"center"
},
flexStyle: {
  display:"flex"
},
flexBoxStyleInfo: {
  display:"flex",
  flexDirection:"row",
  width: "1270px",
  justifyContent:"space-between",
  marginLeft:"90px",
  marginTop:"40px",
  alignItems:"center"
},
DpIcon: {
  width:"96px",
  height:"96px",
  borderRadius:"50%",
  marginLeft:"10px"
},
secondBoxDividerTypography1: {
  fontSize:"16px", 
  fontWeight:700, 
  fontFamily:"Plus Jakarta Sans", 
  display:"flex", 
  justifyContent:"center"
},
secondBoxDividerTypography2: {
  fontSize:"16px", 
  fontWeight:400, 
  fontFamily:"Plus Jakarta Sans", 
  display:"flex", 
  justifyContent:"center"
},
dividerStyle: {
  width:"1264px", 
  height:"1px", 
  marginTop:"72px", 
  backgroundColor:'#B6373C'
},
dividerStyle2: {
  width:"1264px", 
  height:"1px", 
  marginTop:"40px", 
  backgroundColor:'#B6373C',
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  marginLeft:"90px",
},
secondBoxDividerBox: {
  height:'78px', 
  width:"443px", 
  borderRadius:"20px", 
  border:"solid 1px", 
  borderColor:"#B6373C", 
  display:"flex", 
  flexDirection:"column", 
  justifyContent:'center', 
  position:"absolute", 
  left:"35%", 
  top:"35%", 
  zIndex:50, 
  backgroundColor:'white'
},
infoDetailsTypography2: {
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  fontSize:"16px", 
  fontWeight:700, 
  marginLeft:"4px",
  fontFamily:"Plus Jakarta Sans",
},
backNextStyle: {
  display:"flex",
  justifyContent:"center",
  alignItems:"center"
},
studentInfoTypography: {
  fontWeight:700, 
  fontSize:"16px", 
  width:"200px",
  fontFamily: "Plus Jakarta Sans"
},
studentInfoTypography2: {
  fontWeight:400, 
  fontSize:"14px",
   width:"150px",
   fontFamily: "Plus Jakarta Sans"
},
InfoOutlinedIconStyle: {
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  backgroundColor:"#D6F6EB", 
  color:"#27A47D", 
  borderRadius:"50%", 
  padding:"4px",
  width:"20px", 
  height:"20px", 
  marginLeft:"8px"
},
InfoOutlinedIconStyle2: {
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center",
  backgroundColor:"#D6F6EB", 
  color:"#27A47D", 
  borderRadius:"50%", 
  padding:"1px", 
  width:"24px", 
  height:"24px"
},
FourthBoxCardHeading: {
  fontSize:"28px", 
  fontWeight:700,
  fontFamily: "Plus Jakarta Sans",
},
FourthBoxCardText: {
  fontSize:"14px", 
  fontWeight:400,
  fontFamily: "Plus Jakarta Sans",
},
CheckCircleOutlineOutlinedIconStyle: {
  width:"80px", 
  height:"80px", 
  color:"#34D399"
},
FourOutlinedIcons: {
  width:"24px", 
  height:"24px"
},
NameTypography: {
  fontFamily: "Plus Jakarta Sans", 
  margin:"0px 10px", 
  fontSize:"18px", 
  fontWeight:700, 
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center"
},
TestIcon:{
  filter: "invert(88%), sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%)"
},
};
const QontoConnector = withStyles(({breakpoints})=>({
  alternativeLabel: {
    top: 13,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#34D399',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#34D399',
    },
  },
  line: {
    borderColor: '#D9D9D9',
    borderTopWidth: "6px",
    borderRadius: 1,
  },
  [breakpoints.down("md")]:{
    alternativeLabel: {
    top: 15,
    left: 'calc(-40% + 0.2px)',
    right: 'calc(60% + 0.1px)',
    },
    line: {
      borderTopWidth: "2px",
    }
  },
  [breakpoints.down("sm")]:{
    alternativeLabel: {
    top: 8,
    left: 'calc(-25% + 0.6px)',
    right: 'calc(70% + 0.6px)',
    },
    line: {
      borderTopWidth: "2px",
    }
  },
}))(StepConnector)
const StepperTypography = styled(Typography)({
  "@media (max-width:960px)": {
    fontWeight:700,
    fontSize:"18px", 
    color:"#059669",
    fontFamily:"Plus Jakarta Sans"
  },
  "@media (min-width:720px)": {
    fontWeight:700,
    fontSize:"16px",
    color:"#059669",
    fontFamily:"Plus Jakarta Sans"
  }  
})
const webstyles2 = {
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 28,
    alignItems: "center",
  },
  active: {
    color: "red",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#34D399",
    zIndex: 1,
    height:"24px",
    width:"24px"
  },
};
const iconStyle = {
    width: '20px',
    height: '20px',
    marginTop:"4px"
  };
  const InfoDetailsTypography = styled(Typography) ({
    display:"flex", 
    justifyContent:"center", 
    alignItems:"center", 
    fontSize:"16px", 
    fontWeight:500,
    fontFamily:"Plus Jakarta Sans",
  });
// Customizable Area End
