export const LogoImage = require("../assets/I12336213951165843340F3UL7iRAKBFLy9Kd2wJViz.svg");
export const DefaultHeroImage = require("../assets/I12336213961183080425F3UL7iRAKBFLy9Kd2wJViz.svg");
export const TravelImage = require("../assets/1233621418F3UL7iRAKBFLy9Kd2wJViz.svg");
export const TourImage = require("../assets/I12336216251221954256F3UL7iRAKBFLy9Kd2wJViz.svg");
export const ArrowImage = require("../assets/I123362141411899816951189981510F3UL7iRAKBFLy9Kd2wJViz.svg");
export const ImageOne = require("../assets/I12336214121189981354F3UL7iRAKBFLy9Kd2wJViz.svg");
export const Engineering = require("../assets/I12336213641191181776F3UL7iRAKBFLy9Kd2wJViz.svg");
export const Law = require("../assets/I12336213651191181776F3UL7iRAKBFLy9Kd2wJViz.svg");
export const Science = require("../assets/I12336213661191181776F3UL7iRAKBFLy9Kd2wJViz.svg");
export const Arts = require("../assets/ARTS.svg");
export const Buisness = require("../assets/BUISNESS.svg");
export const EnglishLanguage = require("../assets/ENGLISHLANGUAGE.svg");
export const Economics = require("../assets/ECONOMICS.svg");
export const Medicine = require("../assets/MEDICINE.svg");
export const IreLandFlag = require("../assets/IRELANDFLAG.svg");
export const AustraliaFlag = require("../assets/AUSTRALIAFLAG.svg");
export const USAFlag = require("../assets/USA.svg");
export const CanadaFlag = require("../assets/CANADAFLAG.svg");
export const CoventryUniversityImage = require("../assets/I12336214041192682633F3UL7iRAKBFLy9Kd2wJViz.svg");
export const CountryFlag = require("../assets/I12336213781192081848F3UL7iRAKBFLy9Kd2wJViz.svg");
export const SchoolHeroSectionPoster = require("../assets/I12336213461183080425F3UL7iRAKBFLy9Kd2wJViz.svg");
export const RightTick = require("../assets/I12336213541185681267F3UL7iRAKBFLy9Kd2wJViz.svg");
export const HamburgerIcon = require("../assets/HAMBURGERICON.svg");
export const DropDownIcon = require("../assets/DROPDOWNICON.svg");
export const UniversityLogo1 = require("../assets/1233621737F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo2 = require("../assets/1233621729F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo3 = require("../assets/1233621730F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo4 = require("../assets/1233621731F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo5 = require("../assets/1233621732F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo6 = require("../assets/1233621734F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo7 = require("../assets/1233621735F3UL7iRAKBFLy9Kd2wJViz.svg");
export const UniversityLogo8 = require("../assets/1233621736F3UL7iRAKBFLy9Kd2wJViz.svg");
export const TimerLogo = require("../assets/I12336217101217532023F3UL7iRAKBFLy9Kd2wJViz.svg")
export const ThreeLineLogo = require("../assets/I12336217111217532023F3UL7iRAKBFLy9Kd2wJViz.svg")
export const LaptopLogo = require("../assets/I12336217121217532023F3UL7iRAKBFLy9Kd2wJViz.svg")
export const ChartLogo = require("../assets/I12336217131217532023F3UL7iRAKBFLy9Kd2wJViz.svg")