// Customizable Area Start
import React from 'react';
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { setStorageData } from 'framework/src/Utilities';
import { Message } from "../../../framework/src/Message";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export interface icolors {'ACCEPTED' : string, 'CANCELLED' : string, 'PROCESSING' : string, 'READY TO ENROLL' : string, 'NOT PAID' : string , 'REFUND IN PROGRESS' : string}
// Customizable Area End
interface S {
    // Customizable Area Start
    toggleDrawer : boolean,
    page: number
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestManagementTableController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            toggleDrawer: false,
            page: 0
        };
        // Customizable Area End
    }

// Customizable Area Start
 handleChangePage = (event: unknown, newPage: number) => {
  this.setState({page : newPage});
};

navigationToAnyPage = (pageName:string) => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    pageName
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };
  onHomeClick = (pageName:string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };
  
  rows = [
    { 
      id : 1, 
      action: 'View detail',
      firstName: 'John', 
      lastName: 'Doe', 
      appId: 12345, 
      studentId: 67890, 
      passportNo: 'ABC123', 
      program: 'Computer Science', 
      school: 'Example School', 
      recruitmentPartner: 'Partner A', 
      paymentDate: '2024-05-15', 
      applyDate: '2024-04-20', 
      startDate: '2024-09-01', 
      status: 'ACCEPTED' as const, 
      currentStage: 'Pre-Payment' 
    },
    { 
        id : 2, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'CANCELLED' as const, 
        currentStage: 'Submission' 
      },
      { 
        id : 3, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'NOT PAID' as const, 
        currentStage: 'Post-Decision' 
      },
      { 
        id : 4, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'ACCEPTED' as const, 
        currentStage: 'Refund' 
      },
      { 
        id : 5, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'PROCESSING' as const, 
        currentStage: 'Post-Submission' 
      },
      { 
        id : 6, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'READY TO ENROLL' as const, 
        currentStage: 'Pre-Arrival' 
      },
      { 
        id : 7, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'REFUND IN PROGRESS' as const, 
        currentStage: 'Pre-Arrival' 
      },
      { 
        id : 8, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'ACCEPTED' as const, 
        currentStage: 'Pre-Arrival' 
      },
      { 
        id : 9, 
        action: 'View detail',
        firstName: 'John', 
        lastName: 'Doe', 
        appId: 12345, 
        studentId: 67890, 
        passportNo: 'ABC123', 
        program: 'Computer Science', 
        school: 'Example School', 
        recruitmentPartner: 'Partner A', 
        paymentDate: '2024-05-15', 
        applyDate: '2024-04-20', 
        startDate: '2024-09-01', 
        status: 'ACCEPTED' as const, 
        currentStage: 'Pre-Arrival' 
      },
    { 
      id : 10, 
      action: 'View detail',
      firstName: 'Jane', 
      lastName: 'Doe', 
      appId: 54321, 
      studentId: 98765, 
      passportNo: 'DEF456', 
      program: 'Mathematics', 
      school: 'Another School', 
      recruitmentPartner: 'Partner B', 
      paymentDate: '2024-05-16', 
      applyDate: '2024-04-25', 
      startDate: '2024-08-01', 
      status: 'ACCEPTED' as const, 
      currentStage: 'Pre-Arrival' 
    },
  ];
// Customizable Area End
}