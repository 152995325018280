import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconFive extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M14.0502 25.7311L8.11016 22.3011C6.91016 21.6011 6.16016 20.3111 6.16016 18.9211V12.0811C6.16016 10.6911 6.91016 9.40109 8.11016 8.70109L14.0502 5.27109C15.2502 4.57109 16.7402 4.57109 17.9502 5.27109L23.8902 8.70109C25.0902 9.40109 25.8402 10.6911 25.8402 12.0811V18.9211C25.8402 20.3111 25.0902 21.6011 23.8902 22.3011L17.9502 25.7311C17.3502 26.0811 16.6702 26.2511 16.0002 26.2511C15.3302 26.2511 14.6502 26.0811 14.0502 25.7311ZM14.8002 6.57108L8.86016 10.0011C8.12016 10.4311 7.66016 11.2211 7.66016 12.0811V18.9211C7.66016 19.7711 8.12016 20.5711 8.86016 21.0011L14.8002 24.4311C15.5402 24.8611 16.4602 24.8611 17.2002 24.4311L23.1402 21.0011C23.8802 20.5711 24.3402 19.7811 24.3402 18.9211V12.0811C24.3402 11.2311 23.8802 10.4311 23.1402 10.0011L17.2002 6.57108C16.8302 6.36108 16.4102 6.25107 16.0002 6.25107C15.5902 6.25107 15.1702 6.36108 14.8002 6.57108Z" fill={this.props.color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.9219 12.1698C12.9219 10.4698 14.3019 9.08984 16.0019 9.08984C17.7019 9.08984 19.0819 10.4698 19.0819 12.1698C19.0819 13.8698 17.7019 15.2498 16.0019 15.2498C14.3019 15.2498 12.9219 13.8698 12.9219 12.1698ZM14.4219 12.1698C14.4219 13.0398 15.1319 13.7498 16.0019 13.7498C16.8719 13.7498 17.5819 13.0398 17.5819 12.1698C17.5819 11.2998 16.8719 10.5898 16.0019 10.5898C15.1319 10.5898 14.4219 11.2998 14.4219 12.1698Z" fill={this.props.color}/>
<path d="M19.25 20.1584C19.25 18.7784 17.79 17.6484 16 17.6484C14.21 17.6484 12.75 18.7784 12.75 20.1584C12.75 20.5684 12.41 20.9084 12 20.9084C11.59 20.9084 11.25 20.5684 11.25 20.1584C11.25 17.9484 13.38 16.1484 16 16.1484C18.62 16.1484 20.75 17.9484 20.75 20.1584C20.75 20.5684 20.41 20.9084 20 20.9084C19.59 20.9084 19.25 20.5684 19.25 20.1584Z" fill={this.props.color}/>
</svg>
        )
    }
}



