// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Country, State } from "country-state-city";
import { CountryCodeObj, CountryInterfaceData, SelectEventType } from "../../../../packages/blocks/contactus/src/ContactusController";
import { ChangeEvent, KeyboardEvent, RefObject, createRef } from "react";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";

export const configJSON = require("./config");

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface PageStateMap {
  [key: string]: {
    file: string;
    errorTxt: string;
    error: string;
  };
}

interface TravelType {
  country: string;
  date: string;
}
interface PersonalInformationType {
  firstName: string;
  lastName: string;
  gender: string;
  maritalStatus: string;
  email: string;
  passport:string;
  associateBranchName:string;
}

export interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}

interface CountryInfo {
  alpha2: string;
  name: string;
  country_code: string;
}

interface ValidResponseType {
  message: string;
  data: UserDetails[];
  meta: {
    message: string;
  };
  countryCode: string
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success:string[]
}

export interface Props {
  navigation: any;
  id: string;
}

interface FilteredData {
  name: string;
}

interface S {
  stateValue: string;
  stateData: Array<FilteredData>;
  countryValue: string;
  countryData: Array<CountryInterfaceData>;
  activeStep:number;
  cityName: string;
  educationDetailsFile:File | null;
  educationDetailsErrorTxt:string;
  educationDetailsError:boolean;
  englishTestScoreFile:File | null;
  englishTestScoreError:boolean;
  englishTestScoreErrorTxt:string;
  emergencyFile:File | null;
  emergencyError:boolean;
  emergencyErrorTxt:string;
  workExperienceFile:File | null;
  workExperienceError:boolean;
  workExperienceErrorTxt:string;
  passportFile:File | null;
  passportError:boolean;
  passportErrorTxt:string;
  passportAndTravelHistory:{
    visaRefusals:TravelType[];
    travelHistories:TravelType[];
  };
  contactNumber: string;
  toggleDrawer: boolean;
  emergencyAndOtherDocument:{
    emergencyPersonName:string;
    emergencyContact:string;
    emergencyEmail:string;
    remarks:string;
  };
  showError:boolean;
  stateErrorTxt: string;
  countryErrorTxt: string;
  contactNumberError: boolean;
  contactNumberErrorTxt: string;
  contactEmerNumberErrorTxt: string;
  countryError: boolean;
  destination: {
    destination_latitude: string;
    destination_longitude: string;
  } | null;
  cutomerData: { [keyVal: string]: string | number };
  customerID: string;
  stateError: boolean;
  cityNameError: boolean;
  cityNameErrorTxt: string;
  successPopUp:boolean;
  emergencyNumberError: boolean;
  userToken:string;
  phoneCountryCode: CountryCodeObj;
  emergencyPhoneCountryCode: CountryCodeObj;
  personalInformation:PersonalInformationType;
  inputRef: RefObject<HTMLInputElement>;
  userDetails: UserDetails;
  contryCodeValue:string | undefined;
  countryCodes: CountryInfo[];
  emercontryCodeValue:string | undefined;
  passportErrorMessage: string;
  isPassportErrorValid:boolean;
  isEmailErrorValid:boolean;
  emailErrorMessage:string;
  shouldBlockNavigation: boolean,
  AssociateName: Array<{id:number, name:string}>
}

interface InvalidResponseType {
  errors: string;
};
interface InvalidResponseErrorType {
  errors: [
      {
          doc: string;
      }
  ]
};

interface SS {}

export default class FormApprovalWorkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";
  studentFormApiCallId:string = "";
  getProfileAPICallId: string = "";
  getCountryCodeAPICallID: string = "";
  validateMobileNoAPICallID: string = "";
  validatePassportAPICallID: string = "";
  validateEmailAPICallID: string = "";
  getAssociateBranchNamesAPICallID: string = "";
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      showError:false,
      cityName: "",
      cityNameError: false,
      cityNameErrorTxt: "*Please select the city",
      educationDetailsFile:null,
      educationDetailsError:false,
      educationDetailsErrorTxt:"",
      userToken:"",
      emergencyFile:null,
      emergencyError:false,
      emergencyErrorTxt:"*Please upload emergency document",
      englishTestScoreFile:null,
      englishTestScoreError:false,
      englishTestScoreErrorTxt:"",
      workExperienceFile:null,
      workExperienceError:false,
      workExperienceErrorTxt:"",
      passportFile:null,
      passportError:false,
      passportErrorTxt:"",
      passportAndTravelHistory:{
        visaRefusals:[{
          country:"none",
          date:""
        }],
        travelHistories:[{
          country:"none",
          date:""
        }],
      },
      emergencyAndOtherDocument:{
        emergencyPersonName:"",
        emergencyContact:"",
        emergencyEmail:"",
        remarks:"",
      },
      toggleDrawer: false,
      stateValue: "none",
      countryErrorTxt: "*Please select the country",
      countryError: false,
      countryValue: "none",
      stateData: [],
      contactNumber: "",
      stateErrorTxt: "*Please select the state",
      stateError: false,
      contactNumberError: false,
      contactNumberErrorTxt: "*Contact number is required",
      contactEmerNumberErrorTxt:"Contact number is required",
      countryData: Country.getAllCountries() as never,
      activeStep:1,
      destination: { destination_latitude: "", destination_longitude: "" },
      cutomerData: {},
      personalInformation:{
        firstName: "",
        lastName: "",
        gender: "",
        maritalStatus: "",
        email: "",
        passport: "",
        associateBranchName:""
      },
      customerID: "",
      phoneCountryCode: {} as CountryCodeObj,
      emergencyPhoneCountryCode: {} as CountryCodeObj,
      successPopUp:false,
      emergencyNumberError: false,
      inputRef: createRef(),
      userDetails: {} as UserDetails,
      contryCodeValue:"",
      countryCodes:[],
      emercontryCodeValue:"",
      passportErrorMessage: "",
      isPassportErrorValid:false,
      isEmailErrorValid:false,
      emailErrorMessage:"",
      shouldBlockNavigation: true,
      AssociateName: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiFormSuccessCall(apiRequestCallId, responseJson);
      }
      if (apiRequestCallId === this.getAssociateBranchNamesAPICallID) {
        this.setAssociateNameResponse(responseJson.data)
      }
      if (responseJson && responseJson.errors) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token")
    this.setState({ userToken: token }, () => this.getFormApprovalProfile())
    await this.getCountryCode()
    await this.getAssociateBranchNames()
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => this.setState({
        shouldBlockNavigation: true
      })
    }
  }

showPopup = (message: any, isError: boolean = false) => {
  if (isError) {
    alert(message);
  } else {
    this.setState({ successPopUp: true}); 
  }
};

  // web events

  setDefaultValue = () => {
    this.setState({
      showError:false,
      cityName: "",
      educationDetailsFile:null,
      emergencyFile:null,
      englishTestScoreFile:null,
      workExperienceFile:null,
      passportFile:null,
      passportAndTravelHistory:{
        visaRefusals:[{
          country:"none",
          date:""
        }],
        travelHistories:[{
          country:"none",
          date:""
        }],
      },
      emergencyAndOtherDocument:{
        emergencyPersonName:"",
        emergencyContact:"",
        emergencyEmail:"",
        remarks:"",
      },
      toggleDrawer: false,
      stateValue: "none",
      countryValue: "none",
      stateData: [],
      contactNumber: "",
      activeStep:1,
      personalInformation:{
        firstName: "",
        lastName: "",
        gender: "",
        maritalStatus: "",
        email: "",
        passport: "",
        associateBranchName:""
      },
      phoneCountryCode: {} as CountryCodeObj,
      emergencyPhoneCountryCode: {} as CountryCodeObj,
    })
  }
  handleCountryChange = (event: SelectEventType) => {
    const statedata = State.getAllStates()
    this.setState({ countryValue: event.target.value as string }, () => {
        const filteredData = statedata.filter((data: { countryCode: string; }) => data.countryCode === event.target.value);
        if (filteredData.length === 0) {
            this.setState({
                stateData: [{
                    name: "N/A"
                }],
                countryErrorTxt: '',
                countryError: false
            })
        }
        else this.setState({
            stateData: filteredData,
            countryErrorTxt: '',
            countryError: false
        })
    }
    )
};

verifyFile = (file: File | null, errorTxt: string, errorState: string,error:string) => {
  if (file) {
    this.nextButtonClick();
  } else {
    this.setState((prevState)=>({
      ...prevState,
      [errorTxt]: `*Please upload ${error} file`,
      [errorState]: true
    }));
  }
}

 isValidPhoneNumber(phoneNumber:string) {
  return  phoneNumber.length === 10
}

isValidEmergencyyEmail = () => {
 const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(this.state.emergencyAndOtherDocument.emergencyEmail)
}

verifyPassportTravels = () => {
  const visaRefusalsValid = this.state.passportAndTravelHistory.visaRefusals.every(visa => visa.country !== "none" && visa.date !== "");
  const travelHistoriesValid = this.state.passportAndTravelHistory.travelHistories.every(travel => travel.country !== "none" && travel.date !== "");

  if (visaRefusalsValid && travelHistoriesValid && this.state.passportFile) {
    this.setState({ showError: false });
    this.nextButtonClick();
  } else {
    this.setState({
      showError: true,
      passportErrorTxt: "*Please upload passport",
      passportError: !this.state.passportFile
    });
  }
}

verifyEducationDetails = () => {
  this.verifyFile(this.state.educationDetailsFile, 'educationDetailsErrorTxt', 'educationDetailsError','education detail');
}

verifyEnglishTestScore = () => {
  this.verifyFile(this.state.englishTestScoreFile, 'englishTestScoreErrorTxt', 'englishTestScoreError','english test score');
}

verifyWorkExperience = () => {
  this.verifyFile(this.state.workExperienceFile, 'workExperienceErrorTxt', 'workExperienceError','work experience');
}

verifyPassportTravel = () => {
  this.verifyFile(this.state.passportFile, 'passportErrorTxt', 'passportError','passport travel');
}

verifyEmergencyData = () => {
  this.verifyFile(this.state.emergencyFile, 'emergencyTxt', 'emergencyError','emergency contact');
}


handleClose = () => {
  this.setState({successPopUp:false})
  this.setDefaultValue()
  this.navigateToStudentFlow();
};

navigateToStudentFlow = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "AddApplication"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
}

addVisaRefusalFields = () => {
  const tempData = [...this.state.passportAndTravelHistory.visaRefusals,
    {country:"none",date:""}]
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,visaRefusals:tempData}})
}

removeVisaRefusalFields = (index:number) => {
  const tempData = this.state.passportAndTravelHistory.visaRefusals.filter((visa,indexValue)=>index !== indexValue)
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,visaRefusals:tempData}})
}

removeTravelhistory = (index:number) => {
  const tempData = this.state.passportAndTravelHistory.travelHistories.filter((visa,indexValue)=>index !== indexValue)
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,travelHistories:tempData}})
}

addVisaRefusalCountry = (event:SelectEventType,id:number) => {
const tempData = this.state.passportAndTravelHistory.visaRefusals.map((visa,index)=> index === id 
? {...visa,country:event.target.value} : visa)
this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,visaRefusals:tempData as TravelType[]}})
}

addVisaRefusalDate = (event:SelectEventType,id:number) => {
  const tempData = this.state.passportAndTravelHistory.visaRefusals.map((visa,index)=>index === id 
  ? {...visa,date:event.target.value} : visa)
  this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,visaRefusals:tempData as TravelType[]}})
  }

  addTravelHistoryFields = () => {
    const tempData = [...this.state.passportAndTravelHistory.travelHistories,
      {country:"none",date:""}]
  this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,travelHistories:tempData}})
  }
  addTravelHistoryCountry = (event:SelectEventType,id:number) => {
  const tempData = this.state.passportAndTravelHistory.travelHistories.map((travel,index)=> index === id 
  ? {...travel,country:event.target.value} : travel)
  this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,travelHistories:tempData as TravelType[]}})
  }
  
  addTravelhistoryDate = (event:SelectEventType,id:number) => {
    const tempData = this.state.passportAndTravelHistory.travelHistories.map((travel,index)=>index === id 
    ? {...travel,date:event.target.value} : travel)
    this.setState({passportAndTravelHistory:{...this.state.passportAndTravelHistory,travelHistories:tempData as TravelType[]}})
    }

    addEmergencyData = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string,key:string,country?: CountryCodeObj) => {
    const value = typeof event === "string" ? event :  event?.target?.value
    this.setState({emergencyAndOtherDocument:{...this.state.emergencyAndOtherDocument,[key]:value}})
    if(country){
      this.setState({emergencyPhoneCountryCode:country})
    }
    }


    postApiCall = async () => {
      const header = {
    token: this.state.userToken,
    contentType: configJSON.studentFormContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const tempData = {
    student:{
      first_name:this.state.personalInformation.firstName,
      last_name:this.state.personalInformation.lastName,
      gender:this.state.personalInformation.gender,
      marital_status:this.state.personalInformation.maritalStatus,
      country:this.state.countryValue,
      state:this.state.stateValue,
      city:this.state.cityName,
      remarks:this.state.emergencyAndOtherDocument.remarks,
      mobile:this.state.contactNumber,
      email:this.state.personalInformation.email,
      associate_branch_name: this.state.personalInformation.associateBranchName,
      passport_number:  this.state.personalInformation.passport,
      emergency_contact_person:this.state.emergencyAndOtherDocument.emergencyPersonName,
      emergency_contact_mobile:this.state.emergencyAndOtherDocument.emergencyContact,
      emergency_contact_email:this.state.emergencyAndOtherDocument.emergencyEmail
    },
    visa_refusals: this.state.passportAndTravelHistory.visaRefusals,
    travel_histories: this.state.passportAndTravelHistory.travelHistories,
    documents:[
      {
        document_category:"Education Documents",
        name: this.state.educationDetailsFile?.name,
        doc:this.state.educationDetailsFile
      },
      {
        document_category:"English Test Score",
        name: this.state.englishTestScoreFile?.name,
        doc:this.state.englishTestScoreFile
      },
      {
        document_category:"Work Experience",
        name: this.state.workExperienceFile?.name,
        doc:this.state.workExperienceFile
      },
      {
        document_category:"Passport Travel",
        name: this.state.passportFile?.name,
        doc:this.state.passportFile
      },
      {
        document_category:"Emergency Contact",
        name: this.state.emergencyFile?.name,
        doc:this.state.emergencyFile
      },
    ]
  }

  const formData = new FormData();

  Object.keys(tempData.student).forEach((student, index) => {
    formData.append(`[student][${student}]`, Object.values(tempData.student)[index]);
  });

tempData.visa_refusals.forEach((visa, index) => {
formData.append(`[visa_refusals][${index}][country]`, visa.country);
formData.append(`[visa_refusals][${index}][date]`, visa.date );
});

tempData.travel_histories.forEach((travel, index) => {
formData.append(`[travel_histories][${index}][country]`, travel.country);
formData.append(`[travel_histories][${index}][date]`, travel.date);});

tempData.documents.forEach((document, index) => {
formData.append(`[documents][${index}][document_category]`, document.document_category);
formData.append(`[documents][${index}][name]`, document.name || ''); 
formData.append(`[documents][${index}][doc]`, document.doc || ''); 
});

  this.studentFormApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.studentFormEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.studentFormApimethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

navigationToAnyPage = (pageName:string) => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    pageName
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};

postStudentFormApi = async () => {
  if(this.state.emergencyAndOtherDocument.emergencyContact === "" || 
  this.state.emergencyAndOtherDocument.emergencyEmail === "" || 
  this.state.emergencyAndOtherDocument.emergencyPersonName === "" 
  ){
        this.setState({showError:true,emergencyError:false,})
  }else if(this.state.emergencyAndOtherDocument.emergencyContact !== ""){
      this.setState({showError:false,emergencyError:false,emergencyNumberError: false,})
      this.postApiCall()
  }
};

handleToggle = () => {
  this.setState({
    toggleDrawer: !this.state.toggleDrawer
  });
};

checkFieldError = () => {
  const fieldsToCheck = [
    {
      value: this.state.countryValue,
      errorTxt: '*Please select the country',
      errorState: 'countryError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.stateValue,
      errorTxt: '*Please select the state',
      errorState: 'stateError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.cityName,
      errorTxt: '*City is required',
      errorState: 'cityNameError',
      setStateFn: this.setState.bind(this)
    },
    {
      value: this.state.contactNumber,
      errorTxt: '*Contact number is required',
      errorState: 'contactNumberError',
      setStateFn: this.setState.bind(this)
    }
  ];

  fieldsToCheck && fieldsToCheck.forEach(field => {
    if (field?.value?.length === 0 || field?.value === "none") {
      this.setState((prevState) => ({
        ...prevState,
        [`${field.errorState}Txt`]: field.errorTxt,
        [field.errorState]: true
      }));
    }
  });
}


nextButtonClick = () => {
  window.scroll({
    top: 0,
    left: 0
  })
  if(this.state.activeStep < 6) {
    this.setState({
      activeStep:this.state.activeStep + 1
    })
  }else {
    this.setState({
      activeStep: 6
    })
  }
};

disableNumericValue = (event: KeyboardEvent) => {
  const key = event.key;
  if (!/^[a-zA-Z ]$/.test(key) || (!isNaN(parseInt(key)) && key !== '.')) {
    event.preventDefault();
  }
};

backButtonClick = () => {
  
  if(this.state.activeStep > 1) {
    this.setState({
      activeStep:this.state.activeStep - 1,
      showError:false
    })
  }else {
   
    this.setState({
      activeStep: 1,
      showError:false
    })
  }
}

backButtonClickCustom=()=>{
  this.props?.navigation?.navigate("LeadManagement")
}

onHomeClick = (pageName:string) => {
  setStorageData("LandingPageActive", pageName)
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "LandingPage"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};

onImageChange = (event: ChangeEvent<HTMLInputElement>, page: string) => {
  const pageStateMap:PageStateMap = {
    educationDetails: {
      file: 'educationDetailsFile',
      errorTxt: 'educationDetailsErrorTxt',
      error: 'educationDetailsError'
    },
    englishTestScore: {
      file: 'englishTestScoreFile',
      errorTxt: 'englishTestScoreErrorTxt',
      error: 'englishTestScoreError'
    },
    workExperience: {
      file: 'workExperienceFile',
      errorTxt: 'workExperienceErrorTxt',
      error: 'workExperienceError'
    },
    passportAndTravels: {
      file: 'passportFile',
      errorTxt: 'passportErrorTxt',
      error: 'passportError'
    },
    emergencyData: {
      file: 'emergencyFile',
      errorTxt: 'emergencyErrorTxt',
      error: 'emergencyError'
    }
  };

  if (event.target.files && event.target.files[0]) {
    const { file, errorTxt, error } = pageStateMap[page];
    const maxSize = 10 * 1000 * 1024;
    const allowedFormats = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (event.target.files[0].size > maxSize) {
      this.setState(prevState => ({
        
        ...prevState,
        [errorTxt]: "*Maximum file size should be 10Mb",
        [error]: true
      }));
    } else if (!allowedFormats.includes(event.target.files[0].type)) {
      this.setState(prevState => ({
        ...prevState,
        [errorTxt]: "*Only PNG, JPG, Doc/.Docx and PDF formats are allowed",
        [error]: true
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        [file]: event.target.files ? event.target.files[0] : null,
        [errorTxt]: "",
        [error]: false
      }));
    }
  }
}

handleContactNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const target = event.target;
  if (target && target.value !== undefined) {
    const contactNumber = target.value.replace(/\D/g, "").trim();
    if (contactNumber === "") {
      this.setState({
        contactNumber: "",
        contactNumberError: true,
        contactNumberErrorTxt: '*Contact number is required'
      });
    } else {
      this.setState({
        contactNumber,
        contactNumberError: false,
        contactNumberErrorTxt: ''
      });
    }
  }
};


handleEmergencyContactNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const contactNumber = event.target.value.replace(/\D/g, "").trim();
  this.setState(prevState => ({
    emergencyAndOtherDocument: {
      ...prevState.emergencyAndOtherDocument,
      emergencyContact: contactNumber
    }
  }));
};

handleCityChange = (event: { target: { value: string; }; }) => {
  const cityName = event.target.value;
  if (cityName === "") {
      this.setState({
          cityName: "",
          cityNameError: true,
          cityNameErrorTxt: 'City is required.'
      });
  } else {
      this.setState({
          cityName: cityName,
          cityNameError: false,
          cityNameErrorTxt: ''
      });
  }
};

handleStateChange = (event: SelectEventType) => {
  this.setState({ stateValue: event.target.value as string, stateError: false });
};

handleClick = () => {
  return this.state.inputRef.current
    ? this.state.inputRef.current.click()
    : "";
};

formApprovalApiCall = async (apiData: APIPayloadType) => {
  const { contentType, method, body, type,endPoint } = apiData;
  let token = await getStorageData("token")
  const header = {
    "Content-Type": contentType,
    token: token
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  body && type !== "formData"
    ? requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    : requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return requestMessage.messageId;
};

apiFormSuccessCall = (apiRequestCallId: string, responseJson: UserDetailsResponse) => {
  if (apiRequestCallId === this.getProfileAPICallId) {
    this.getProfileFormSuccessCallBack(responseJson);
  }
  if(apiRequestCallId === this.getCountryCodeAPICallID) {
    this.setCountryCodes(responseJson)
  }
  if (apiRequestCallId === this.studentFormApiCallId) {

    if (responseJson) {
      let errorMessage = "";
      let successMessage = "";
      if (responseJson.error) {
        if (Array.isArray(responseJson.error)) {
          errorMessage = responseJson.error.join(". ");
        } else {
          errorMessage = responseJson.error;
        }
        // Show error message via alert
        this.showPopup(`Error: ${errorMessage}`, true);
      } else {
        // Show success message if there are no errors
        successMessage = responseJson.meta?.message || "Form submitted successfully!";
        this.showPopup(successMessage);
      }
      this.studentFormApiCallId = "";
    }
  }
  
  
};

apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType & InvalidResponseErrorType) => {
  if (apiRequestCallId === this.validateEmailAPICallID) {
      this.setEmailValidation(responseJson);
  }
  if(apiRequestCallId === this.validatePassportAPICallID){
    this.setPassportValidation(responseJson)
  }
  if(apiRequestCallId === this.validateMobileNoAPICallID){
    this.setContactErrors(responseJson)
  }
};

isValidResponse = (responseJson: ValidResponseType) => {
  return (responseJson && responseJson.data) || responseJson.message || [];
};

setCountryCodes = (responseJson: any) => {
  this.setState({countryCodes:responseJson})
  const defaultCountry = this.state.countryCodes.find((country) => country.alpha2 === 'IN');
  this.setState({contryCodeValue:`${defaultCountry?.country_code}-${defaultCountry?.alpha2}`, emercontryCodeValue:`${defaultCountry?.country_code}-${defaultCountry?.alpha2}`})
};

  setAssociateNameResponse = (response: Array<{
      id: string;
      type: string;
      attributes: {
        id: number;
        type: string;
        branch_name: string;
        email: string;
        country_code: string;
        phone_number: number;
        managed_by: string;
        date: string; 
        time: string;
        created_at: Date;
        updated_at: Date;
      };
    }>
  ) => {
    const data = response.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.branch_name
      }
    })
    this.setState({
      AssociateName: data
    })
  }

setContactErrors = (responseJson: any) => {
    const errorMessage = responseJson.errors?.[0]?.message
    this.state.activeStep === 1 ? this.setState({contactNumberError: true, contactNumberErrorTxt:errorMessage}):this.setState({emergencyNumberError: true, contactEmerNumberErrorTxt:errorMessage})
    this.validateMobileNoAPICallID = ""
}

setPassportValidation = (responseJson: any) => {
  this.setState({ passportErrorMessage: responseJson.errors, isPassportErrorValid: true })
};

setEmailValidation = (responseJson: any) => {
  this.setState({ emailErrorMessage: responseJson.errors, isEmailErrorValid: true })
};

getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
  this.setState({ userDetails: responseJson.data });
};

getFormApprovalProfile = async () => {
  let userDetails = await getStorageData("userDetails")
  this.getProfileAPICallId = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getProfileEndPoint + userDetails
  });
};

removeEducationFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({educationDetailsFile:null})
}

removeEnglishTestScoreFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({englishTestScoreFile:null})
}

removeWorkExperienceFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({workExperienceFile:null})
}

removePassportFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({passportFile:null})
}

removeEmergencyFile = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
  this.setState({emergencyFile:null})
}

getCountryCode = async () => {
  this.getCountryCodeAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getCountryCode
  });
}

getAssociateBranchNames = async () => {
  this.getAssociateBranchNamesAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: configJSON.getAssociateBranchNames
  });
}

validateMobileNo = () => {
  if (this.state.contactNumber && this.state.contryCodeValue) {
    this.validateMobile(this.state.contactNumber, this.state.contryCodeValue);
  }
}

validateEmerMobileNo = () => {
  if (this.state.emergencyAndOtherDocument.emergencyContact && this.state.emercontryCodeValue) {
    this.validateMobile(this.state.emergencyAndOtherDocument.emergencyContact, this.state.emercontryCodeValue);
  }
}

validateMobile = async (mobileno:string, countrycode:string) => {
  this.validateMobileNoAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validateMobileNumber}?mobile=${mobileno}&country_code=${countrycode}`
  });
}

handleCountryCodeChange = (event: SelectEventType) => {
  this.setState({ contryCodeValue: event.target.value as string, stateError: false });
};

handleEmerCountryCodeChange = (event: SelectEventType) => {
  this.setState({ emercontryCodeValue: event.target.value as string, stateError: false });
};

validatePassportNo = (event: React.FocusEvent<HTMLInputElement>) => {
  this.setState({passportErrorMessage:""})
  this.setState({isPassportErrorValid: false})
  if (event?.target?.value) {
    this.validatePassport(event?.target?.value);
  }
}

validatePassport = async (passportNo:string) => {
  this.validatePassportAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validatePassport}?data[attributes][passport_number]=${passportNo}`
  });
}

validatePersonalEmail = (event: React.FocusEvent<HTMLInputElement>) => {
  this.setState({ emailErrorMessage: "" })
  this.setState({ isEmailErrorValid: false })
  if (event?.target?.value) {
    this.validateEmail(event?.target?.value);
  }
}

validateEmail = async (emailid:string) => {
  this.validateEmailAPICallID = await this.formApprovalApiCall({
    contentType: configJSON.validationApiContentType,
    method: configJSON.validationApiMethodType,
    endPoint: `${configJSON.validateEmail}?data[attributes][email]=${emailid}`
  });
}

navigateToOtherService = () => {
  const navigation = new Message(getName(MessageEnum.NavigationMessage));
  navigation.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "OtherServices"
  );
  navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(navigation);
};

}

// Customizable Area End
