import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconEleven extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M14.4902 5.72859L9.50016 7.60859C8.35016 8.03859 7.41016 9.39859 7.41016 10.6186V18.0486C7.41016 19.2286 8.19016 20.7786 9.14016 21.4886L13.4402 24.6986C14.8502 25.7586 17.1702 25.7586 18.5802 24.6986L22.8802 21.4886C23.8302 20.7786 24.6102 19.2286 24.6102 18.0486V10.6186C24.6102 9.38859 23.6702 8.02859 22.5202 7.59859L17.5302 5.72859C16.6802 5.41859 15.3202 5.41859 14.4902 5.72859Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.0508 15.3697L14.6608 16.9797L18.9608 12.6797" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



