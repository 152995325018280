// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { setStorageData, getStorageData } from "../../../../packages/framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export interface SelectEventType {
  target: {
    value: string | unknown;
  }
}
export interface Offers {
  id: string;
  attributes: {
    title: "Offer";
    university: "BPP University";
    description: "OfferOfferOffer";
    validity_period: "2024-07-10";
    terms_and_conditions: "OfferOffer";
    promo_code : string;
    discount_value : string

  }
}

export interface UserDetails {
  attributes: {
    role: string;
    first_name: string;
    image: {
      url: string;
    };
  }
}

export interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
}

interface APIPayloadType {
  endPoint: string;
  method: string;
  body?: object;
  token?: string;
  contentType?: string;
  type?: string;
}


export interface OfferData {
  data: Offers[],
  meta: {
    filter_count: "0"
  }
}

export interface UniversityResponse {
  university_name: []
}
// Customizable Area End

interface S {
    // Customizable Area Start
  offersData: Array<Offers>;
  isFiltered: boolean;
  open: boolean;
  universityValue: string | unknown;
  toggleDrawer: boolean;
  dateValue: string;
  isCalender: boolean;
  expiryDate: Date | null | string;
  expiryDateValue: string;
  userDetails: UserDetails;
  universityData: Array<string>;
  filterCount: string;
  openTooltipId:null|string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardOfferpController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
  offerApiCallId: string = "";
  getProfileAPICallId: string = "";
  getFilterAPICallId: string = "";
  universityAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage)
        ];
    this.state = {
      offersData: [],
      isFiltered: false,
      open: false,
      universityValue: "none",
      toggleDrawer: false,
      dateValue: "fff",
      isCalender: false,
      expiryDate: "",
      expiryDateValue: configJSON.expiryPlaceholder,
      userDetails: {} as UserDetails,
      universityData: [],
      filterCount: "",
      openTooltipId:null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(apiRequestCallId, responseJson);
        }
      }
        // Customizable Area End
    }
    // Customizable Area Start


  async componentDidMount() {
    this.getUniversityApi();
    this.getOfferApi();
    this.getFormProfileAccount();
    window.scroll({
      top: 0,
      left: 0
    })
  };

  handleOpenClose = () => {
    this.setState({ open: !this.state.open });
  };

  handlApplyFilter = () => {
    this.getFilterApi();
    this.setState({
      isFiltered: true,
    });
    this.handleOpenClose();
  };

  handleClearFilter = () => {
    this.setState(
      {
        isFiltered: false,
      },
      () => {
        this.handleOpenClose();
        this.getOfferApi();
        this.onResetClick();
      }
    );
  };

  universityChange = (event: SelectEventType) => {
    this.setState({ universityValue: event.target.value });
  };

  onResetClick = () => {
    const currentYear = new Date().getFullYear();
    this.setState({
      universityValue: "none",
      expiryDateValue: configJSON.expiryPlaceholder,
      expiryDate: new Date(currentYear, 0, 1)
    }, () => { this.getOfferApi() })
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onCalenderClick = () => {
    this.setState({ isCalender: !this.state.isCalender });
  };

  handleDateChange = (date: Date) => {
    const dayValue = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${dayValue}`;
    this.setState({
      expiryDate: date,
      isCalender: !this.state.isCalender,
      expiryDateValue: formattedDate
    })
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      :
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: UserDetailsResponse & OfferData & UniversityResponse) => {
    if (apiRequestCallId === this.getProfileAPICallId) {
      this.profilePicSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getFilterAPICallId) {
      this.filterSucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.universityAPICallId) {
      this.universitySucessCallBack(responseJson);
    }

    if (apiRequestCallId === this.offerApiCallId) {
      this.offerSucessCallBack(responseJson);
    }
  };

  profilePicSucessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  filterSucessCallBack = (responseJson: OfferData) => {
    this.setState({ offersData: responseJson.data, filterCount: responseJson.meta.filter_count });
  };

  universitySucessCallBack = (responseJson: UniversityResponse) => {
    this.setState({ universityData: responseJson.university_name });
  };

  offerSucessCallBack = (responseJson: OfferData) => {
    this.setState({ offersData: responseJson.data, filterCount: "" });
  };

  isValidResponse = (responseJson: UserDetailsResponse & UniversityResponse) => {
    return (
      (responseJson && responseJson.data) || responseJson.university_name || responseJson.data
    );
  };

  getFormProfileAccount = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  };

  getFilterApi = async () => {
    let universityFilter = "" as unknown;
    let expiryDateValue = "";
    if (this.state.universityValue !== "none") {
      universityFilter = this.state.universityValue;
    }
    if (this.state.expiryDateValue !== configJSON.expiryPlaceholder) {
      expiryDateValue = this.state.expiryDateValue;
    }
  
    let endpoint = `${configJSON.filterApiEndPoint}?`;
  
    if (universityFilter) {
      endpoint += `university=${universityFilter}&`;
    }
    if (expiryDateValue) {
      endpoint += `expiry_date=${expiryDateValue}&`;
    }
  
    this.getFilterAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: endpoint
    });
  };

  getUniversityApi = async () => {
    this.universityAPICallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.universityEndPoint
    });
  };

  getOfferApi = async () => {
    this.offerApiCallId = await this.apiCall({
      method: configJSON.dashboarApiMethodType,
      contentType: configJSON.dashboarContentType,
      endPoint: configJSON.getOfferEndPoint
    });
  };
  
  handleTooltipOpen = (idValue: string) => {
    this.setState({
        openTooltipId: idValue
    });
};

handleTooltipClose = () => {
    this.setState({
        openTooltipId: null
    });
};

    // Customizable Area End
}
