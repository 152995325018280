export const TotalApplicationIcon = require("../assets/product_card_small_.png");
export const PaidApplicationIcon = require("../assets/product_card_small_Component 3.png");
export const RejectedIcon = require("../assets/product_card_small_Component 4.png");
export const StudentIcon = require("../assets/product_card_small_Component 5.png");
export const cardImage = require("../assets/image_.png");
export const countryImage1 = require("../assets/view_.png");
export const countryImage2 = require("../assets/view_(1).png");
export const countryImage3 = require("../assets/view_(2).png");
export const countryImage4 = require("../assets/view_(3).png");
export const countryImage5 = require("../assets/view_(4).png");
export const countryImage6 = require("../assets/view_(6).png");
export const girlImage = require("../assets/viewcircle_Ellipse 131.png");
export const enrolledImg = require("../assets/enrolledImg.svg");
export const studentImg = require("../assets/studentImg.svg");
export const infoIcon = require("../assets/infoIcon.svg");
export const canadaImg = require("../assets/canadaImg.svg");
export const germanyImg = require("../assets/germanyImg.svg");
export const airlineImg = require("../assets/airlineImg.svg");
export const voucherImg = require("../assets/voucherImg.svg");
export const successImg = require("../assets/successImg.svg");
