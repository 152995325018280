import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DocumentDistributionController, {
  Props,
  configJSON,
} from "./DocumentDistributionController";

export default class DocumentDistribution extends DocumentDistributionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <Typography variant="h3">{configJSON.labelTitleText}</Typography>
            <div>
              <Box style={webStyles.boxMargin}>
                <Box>
                  <Typography variant="h6">
                    {configJSON.enterAccountIdToShare}
                  </Typography>
                </Box>
                <TextField
                  data-test-id="InputSharedId"
                  variant="outlined"
                  type="number"
                  fullWidth={true}
                  onChange={(event) => this.handleInput("sharedId", event.target.value)}
                />
                <Box style={webStyles.boxMargin}>
                  <Input
                    data-test-id="InputFile"
                    type="file"
                    onChange={this.onFileChange}
                  />
                  <Button
                    data-test-id="UploadBtn"
                    disabled={!(this.state.sharedId && this.state.selectedFile)}
                    onClick={this.onFileUpload}
                  >
                    {configJSON.UploadBtn}
                  </Button>
                </Box>
              </Box>
            </div>

            <div>
              <Button
                data-test-id="navigateToSharedDocumentList"
                onClick={this.navigateToSharedDocumentList}
              >
                {configJSON.SharedDocumentBtn}
              </Button>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  boxMargin: { marginTop: "20px" },
};
// Customizable Area End
