import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconThirteen extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M13 25.5H19C24 25.5 26 23.5 26 18.5V12.5C26 7.5 24 5.5 19 5.5H13C8 5.5 6 7.5 6 12.5V18.5C6 23.5 8 25.5 13 25.5Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.75 12.5H12.25" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.75 18.5H12.25" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



