import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconTwelve extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M26 20.2399V8.16994C26 6.96994 25.02 6.07994 23.83 6.17994H23.77C21.67 6.35994 18.48 7.42994 16.7 8.54994L16.53 8.65994C16.24 8.83994 15.76 8.83994 15.47 8.65994L15.22 8.50994C13.44 7.39994 10.26 6.33994 8.16 6.16994C6.97 6.06994 6 6.96994 6 8.15994V20.2399C6 21.1999 6.78 22.0999 7.74 22.2199L8.03 22.2599C10.2 22.5499 13.55 23.6499 15.47 24.6999L15.51 24.7199C15.78 24.8699 16.21 24.8699 16.47 24.7199C18.39 23.6599 21.75 22.5499 23.93 22.2599L24.26 22.2199C25.22 22.0999 26 21.1999 26 20.2399Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 8.98999V23.99" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.75 11.99H9.5" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 14.99H9.5" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



