import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconThree extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M22.04 17.05C21.62 17.46 21.38 18.05 21.44 18.68C21.53 19.76 22.52 20.55 23.6 20.55H25.5V21.74C25.5 23.81 23.81 25.5 21.74 25.5H10.26C8.19 25.5 6.5 23.81 6.5 21.74V15.01C6.5 12.94 8.19 11.25 10.26 11.25H21.74C23.81 11.25 25.5 12.94 25.5 15.01V16.45H23.48C22.92 16.45 22.41 16.67 22.04 17.05Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.5 15.9101V11.3401C6.5 10.1501 7.23 9.09006 8.34 8.67006L16.28 5.67006C17.52 5.20006 18.85 6.12009 18.85 7.45009V11.2501" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M26.5588 17.4702V19.5302C26.5588 20.0802 26.1188 20.5302 25.5588 20.5502H23.5988C22.5188 20.5502 21.5288 19.7602 21.4388 18.6802C21.3788 18.0502 21.6188 17.4602 22.0388 17.0502C22.4088 16.6702 22.9188 16.4502 23.4788 16.4502H25.5588C26.1188 16.4702 26.5588 16.9202 26.5588 17.4702Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 15.5H18" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }
}



