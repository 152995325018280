// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface ValidResponseType {
    message: object;
    data: object;
}

interface InvalidResponseType {
    errors: [
        {
            message: "Password and Confirm password should be same"
        }
    ]
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}
// Customizable Area End

interface S {
    // Customizable Area Start
    confrimPswrdValue: string;
    pswrdValue: string;
    pswrdError: boolean;
    pswrdErrorTxt: string;
    confrimPswrdError: boolean;
    confrimPswrdErrorTxt: string;
    isAlert: boolean;
    isSuccess: boolean;
    alertMsg: string;
    alertType: Color;
    token: string;
    toggleDrawer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    newPswrdAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            confrimPswrdValue: "",
            pswrdValue: "",
            pswrdError: false,
            pswrdErrorTxt: "",
            confrimPswrdError: false,
            confrimPswrdErrorTxt: "",
            isAlert: false,
            isSuccess: false,
            alertMsg: "",
            alertType: "success",
            token: "",
            toggleDrawer: false
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.setState({ token: window.location.href.split('set_password/')[1] });
    }

    handlePswrdChange = (event: { target: { value: string } }) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
        const pswrdValue = event.target.value;
        this.setState({ pswrdValue: pswrdValue }, () => {
            if (pswrdValue === "") {
                this.setState({
                    pswrdError: true,
                    pswrdErrorTxt: "Password is Required.",
                });
            }
            else if (!passwordRegex.test(pswrdValue)) {
                this.setState({
                    pswrdError: true,
                    pswrdErrorTxt:
                        "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
                });
            } else {
                this.setState({ pswrdError: false, pswrdErrorTxt: "" });
            }
        });
    };

    handleConfirmPswrdChange = (event: { target: { value: string } }) => {
        this.setState({ confrimPswrdValue: event.target.value }, () => {
            if (this.state.confrimPswrdValue === "") {
                this.setState({
                    confrimPswrdError: true,
                    confrimPswrdErrorTxt: "Confirm Password is Required.",
                });
            } else if (this.state.pswrdValue != this.state.confrimPswrdValue) {
                this.setState({
                    confrimPswrdError: true,
                    confrimPswrdErrorTxt: "Password and Confirm Password doesn't match.",
                });
            } else {
                this.setState({ confrimPswrdError: false, confrimPswrdErrorTxt: "" });
            }
        });
    };

    onSetPswrdClick = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
        let isCheck = true
        if (this.state.pswrdValue.length === 0 || !passwordRegex.test(this.state.pswrdValue)) {
            isCheck = false
            this.setState({ pswrdError: true, pswrdErrorTxt: "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character." })
        }
        if (this.state.pswrdValue.length === 0) {
            isCheck = false
            this.setState({ pswrdError: true, pswrdErrorTxt: "Password is required." });
        }
        if (this.state.confrimPswrdValue === "") {
            isCheck = false
            this.setState({
                confrimPswrdErrorTxt: "Confrim password is required.",
                confrimPswrdError: true
            })
        }
        if (this.state.pswrdValue !== this.state.confrimPswrdValue) {
            isCheck = false
            this.setState({
                confrimPswrdErrorTxt: "Password and Confirm Password doesn't match.",
                confrimPswrdError: true
            })
        }
        if (isCheck) {
            this.postNewpswrd()
        }
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    navigateToLoginPage = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId);
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson);
            }
        }
    }

    apiCall = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSucessCall = async (apiRequestCallId: string,) => {
        if (apiRequestCallId === this.newPswrdAPICallId) {
            this.newPswrdSucessCallBack();
        }
    };

    newPswrdSucessCallBack = () => {
        this.setState({ isSuccess: true })
    }

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.newPswrdAPICallId) {
            this.newPswrdFailureCallBack(responseJson);
        }
    };

    newPswrdFailureCallBack = (responseJson: InvalidResponseType) => {
        this.setState({ isAlert: true, alertType: "error", alertMsg: responseJson.errors[0].message })
    }

    postNewpswrd = async () => {
        const body = {
            data: {
                password_confirmation: this.state.confrimPswrdValue,
                password: this.state.pswrdValue,
                token: this.state.token
            }
        }
        this.newPswrdAPICallId = await this.apiCall({
            contentType: configJSON.newPswrdAPIContentType,
            method: configJSON.newPswrdAPIApimethod,
            endPoint: configJSON.newPswrdAPIEndPoint,
            body: body
        });
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };
    // Customizable Area End
}