import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconFour extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M14.0495 6.03004L8.02953 9.96004C6.09953 11.22 6.09953 14.04 8.02953 15.3L14.0495 19.23C15.1295 19.94 16.9095 19.94 17.9895 19.23L23.9795 15.3C25.8995 14.04 25.8995 11.23 23.9795 9.97004L17.9895 6.04004C16.9095 5.32004 15.1295 5.32004 14.0495 6.03004Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.63109 16.5801L9.62109 21.2701C9.62109 22.5401 10.6011 23.9001 11.8011 24.3001L14.9911 25.3601C15.5411 25.5401 16.4511 25.5401 17.0111 25.3601L20.2011 24.3001C21.4011 23.9001 22.3811 22.5401 22.3811 21.2701V16.6301" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.3984 18.5V12.5" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



