import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName,} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import countrylist from "./CountryCodeList";
import { setStorageData } from 'framework/src/Utilities';

export interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
export interface StyledTabProps {
  label: string;
}

export const configJSON = require("./config");
const navigation = require("react-navigation");

export interface Props {
  navigation: typeof navigation;
  id: string;/*  */
}
// Customizable Area End
const { baseURL: apiBaseUrl } = require('./../../../framework/src/config')

interface StateType {
  // Customizable Area Start
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string,
  selectedGender: string,
  selectedPhoneCountry: string,
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  countries: Array<object>,
  gender: Array<Gender>,
  country: Array<Country>,
  industry: Array<Industry>,
  selectedIndustry: string,
  token_local: string | null,
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;
  formikData: FormikData;
  toggleDrawer : boolean,
  value : number,
  valueCustom: number,
  activeStepsNew: number;
  // Customizable Area End
}

// Customizable Area Start
interface FormikData {
  first_name : string,
  last_name : string,
  email : string,
  selectedPhoneCountry: string,
  phone_number: string,
  selectedGender?: string,
  selectedCountry? : string,
  message?: string,
  selectedIndustry? : string,
  countries: Array<object>
}

interface CustomTypeData
{
  contentType: string,
  method: string,
  body: {
    data: CustomTypeBodyData
  },
  endPoint: string,
}

interface CustomTypeBodyData{
  attributes: {
      first_name: string,
      last_name:string,
      phone_number: string,
      email: string,
      gender: string,
      country: string,
      industry: string,
      message: string
    },
    type: string,
  }



interface CustomTypeSubmitvalues {
  first_name: string,
  last_name: string, 
  selectedPhoneCountry: string, 
  phone_number: string, 
  email: string, 
  countries: Array<object>,
  selectedGender?: string, 
  selectedCountry?: string, 
  selectedIndustry?: string, 
  message?: string,
}

interface CustomTypevalues {
  first_name: string,
  last_name: string, 
  selectedPhoneCountry: string, 
  phone_number: string, 
  email: string, 
  selectedGender: string, 
  selectedCountry: string, 
  selectedIndustry: string, 
  message: string,
}

export interface CountriesList {
  Countries : Array<Countries> ;

}

 interface Countries {
    name: string,
    dial_code: string,
    code: string
  }

export interface Gender {
   label: string, 
   value: string 
}

export interface Industry {
  label: string, 
  value: string 
}
export interface Country {
  label: string, 
  value: string 
}

interface SSType {
  SSTypeid: number;
}
// Customizable Area End

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      countries: countrylist,
      formikData: {
        selectedCountry: "1",
        selectedGender: "1",
        selectedIndustry: "education",
        first_name: "",
        last_name: "",
        email: "",
        selectedPhoneCountry: "",
        message: "",
        phone_number: "",
        countries: countrylist
      },
      
      // Add more countries as neede
      gender: [
        { label: 'Male', value: '1' },
        { label: 'Female', value: '2' },
      ],
      country: [
        { label: 'India', value: '1' },
        { label: 'America', value: '2' },
        { label: 'Canada', value: '3' },
        { label: 'England', value: '4' },
      ],
      industry: [
        { label: 'Education', value: 'education' },
        { label: 'Food', value: 'food' },
        { label: 'Marketing', value: 'marketing' }
      ],
      selectedIndustry: '',
      token_local: localStorage.getItem("loginTokenBlock"),
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      toggleDrawer: false,
      value: 0,
      valueCustom: 0,
      activeStepsNew: 1
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  
   // Customizable Area Start

  getStepsNew() {
    return ["New Lead", "Pre-Submission", "Submitted", "Offer Letter", "Paid", "Pre-CAS", "Visa Filing", "Ready to Enrol"];
    // Customizable Area End
}

// Customizable Area Start
   handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      value : newValue
    });
  };

  handleChangeCustom = (event: React.ChangeEvent<{}>, newValueCustom: number) => {
    this.setState({
      valueCustom : newValueCustom
    });
  };

   handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };
  onHomeClick = (pageName:string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }
  // Customizable Area End

}
