import React from "react";
// Customizable Area Start

import LandingPageController, { Props } from "./LandingPageController.web";
export const configJSON = require("./config");
import {
  ArrowImage,
  LogoImage,
  RightTick,
  TourImage,
  TravelImage,
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Toolbar,
  Typography,
  styled,
  Drawer,
  IconButton,
} from "@material-ui/core";
import Footer from "../../../components/src/Footer.web";
import { Close } from "@material-ui/icons";
import AppHeader from "../../../../packages/components/src/AppHeader.web";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#BB393D",
    },
    secondary: {
      main: "#059669",
    },
  },
  typography: {
    fontFamily: "Plus Jakarta Sans",
  },
});






// Customizable Area End
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    // Customizable Area End
    }

  // Customizable Area Start

  renderCommanSection = () => {
    return (
      <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      direction="row"
      
    >
      <Box
        sx={webStyles.buttonBox}
      >
         {this.state.countryName.map((item) => (
      <Button
      size="small"
      style={{
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }}
      variant="outlined"
      >
        <Typography>{item.countryName}</Typography>
      </Button>
    ))}
      </Box>
  </Grid>
    );
  };

  SchoolBanner = () => {
    return (
      <Banner>
        <BannerUpDiv
          component={"div"}
        >
          <img 
          width={"100%"} 
          height={"100%"} 
          src={this.state.bannerData?.school?.attributes?.url?.url} 
          alt="loading..." />
        </BannerUpDiv><BannerTxtDiv>
          <Box style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"4px"}}>
              <BannerHeader variant="h2"> {this.state.bannerData?.school?.attributes?.title}
              </BannerHeader>
               <BannerSubHeader variant="subtitle1" style={{ color: "white",textWrap:"wrap" }}> 
               {this.state.bannerData?.school?.attributes?.description}</BannerSubHeader>
              </Box></BannerTxtDiv>
      </Banner>
    );
  };

  RecruiterBanner = () => {
    return (
     <Banner>
        <BannerUpDiv component={"div"}><img width={"100%"} height={"100%"} src={this.state.bannerData?.recruiter && this.state.bannerData.recruiter.attributes.url.url} alt="loading..." /></BannerUpDiv>
        <BannerTxtDiv>
          <Box style={{width:"100%",height:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"4px"}}>
              <BannerHeader>
                {this.state.bannerData?.recruiter?.attributes?.title}
              </BannerHeader>
               <BannerSubHeader> {this.state.bannerData?.recruiter?.attributes?.description}
              </BannerSubHeader>
              </Box>
          </BannerTxtDiv>
      </Banner> 
    );
  };

  renderGetStartedApplyBoard = () => {
    return (
      <Box sx={webStyles.getStartedBoardApplyMainBox} >
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{gap:"41px"}}
          >
            <Grid item xs={12} container style={{gap:"21px"}}>
              <CenterTypography
                >
                {configJSON.everythingInOnePlace}
              </CenterTypography>
              <CenterTypographyTwo
              >
                {configJSON.textlabel11}
              </CenterTypographyTwo>
            </Grid>
            
            <Grid
                item
                xs={12}
                style={{display:"flex"}}
                justifyContent="center"
                alignItems="center"
              >
                <TabBox>
                  {this.state.allServices.map((service,index)=>(
                    <TabButton
                    key={service.id}
                    style={{ backgroundColor: this.state.selectedTab === service.id ? "#059669" : "white",
                  color:this.state.selectedTab === service.id ? "white" : "black"}}
                  data-test-id={`recruiterTab${index}`}
                    onClick={()=>this.tabSelected(service.id)}
                  >
                    <TabTypography>{service.serviceName}</TabTypography>
                  </TabButton>
                  ))}
                </TabBox>
              </Grid>
              
            {Array.isArray(this.state.middleCardData.recruiter) && this.state.middleCardData.recruiter.length>0 ? this.state.middleCardData.recruiter.map((item) => (
              <>
              <Grid item xs={12} md={4} sm={6} lg={3} style={{display:"flex"}} justifyContent="center">
                <ImageCard>
                <UnderImageBox width="full" height="10.813rem">
                        <img src={item.attributes.url?.url} width="100%" height="100%" />
                        <GradiantColorBox width="100%" height="100%" />
                      </UnderImageBox>
                      <Box style={{display:"flex",height:"50%",flexDirection:"column",justifyContent:"space-between",alignItems:"start"}}>
                  <CardContent>
                    <ImageBoxTextHeader>{item.attributes.title}</ImageBoxTextHeader>
                    <ImageBoxTextDescription>{item.attributes.description}</ImageBoxTextDescription>   
                  </CardContent>
                  <Button variant="text" color="primary"
                    endIcon={
                      <Box display="flex" width="20px" height="20px">
                        <img src={ArrowImage} width={"100%"} height={"100%"} />
                      </Box>
                    }
                    style={{ paddingLeft:"1rem" }}
                    disableRipple={true}
                  >
                  <Typography
                      variant="subtitle2"
                      style={{...webStyles.fontBold,color:"#BB393D"}}
                    >
                      Learn More
                    </Typography>
                  </Button>
                  </Box>
                </ImageCard>
              </Grid>
             </>
            )): (
              <CenterTypographyTwo> There is no Data...! 
              </CenterTypographyTwo>
            )}
          </Grid>
        </Container>
      </Box>
    );
  };

  ApplyBoard = () => {
    return (
      <Box sx={{ pt: 10, position: "relative", marginY: 10 }}>
        <Container maxWidth="lg">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  textAlign: "Center",
                  height: "25%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    position: "relative",
                    fontSize: 38,
                    letterSpacing: 1.5,
                    fontWeight: "700",
                    lineHeight: 1.3,
                  }}
                >
                  {configJSON.textlabelString4}
                </Typography>
              </Box>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Box
                  style={{
                    border: "1px solid #059669",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "50px",
                    padding: "2px",
                    gap: 50,
                  }}
                >
                  <Button
                    style={{ backgroundColor: "#059669", borderRadius: "50px" }}
                  >
                    <Typography>Student</Typography>
                  </Button>
                  <Button
                    style={{ backgroundColor: "#059669", borderRadius: "50px" }}
                  >
                    <Typography>Recruitment</Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
                style={{ position: "relative" }}
              >
                <Box
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <img src={TravelImage} width={300} height={300} />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  pb={10}
                >
                  <CommanButton>{configJSON.btnTyitToday}</CommanButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  ServicesEverythingInonePlace = () => {
    return (
          <Grid
          style={{gap:"5rem",padding:"5rem 0",background: "#F7F7F7"}}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} justifyContent="center">
            <CenterTypography
             style={{marginBottom:"5rem",margin:"auto",width:"80vw"}}>

                {configJSON.textlabelString2}
              </CenterTypography>
            </Grid>
            
            {Array.isArray(this.state.middleCardData.student) && this.state.middleCardData.student.length>0 ? this.state.middleCardData.student.map((item) => (
            <Grid item xs={12} md={4} sm={6} lg={3} justifyContent="center" style={{display:"flex"}}>
              <ImageCard>
              <UnderImageBox width="full" height="10.813rem">
                      <img src={item.attributes.url?.url} width="100%" height="100%" />
                      <GradiantColorBox width="100%" height="100%" />
                    </UnderImageBox>
                <CardContent style={{display:"flex",justifyContent:"space-around",flexDirection:"column",alignItems:"center",height:"50%"}}>
                  <Box><TextCenter>{item.attributes.title}</TextCenter>
                  <ImageBoxTextDescription style={{textAlign:"center"}}>{item.attributes.description}</ImageBoxTextDescription>
                  </Box>
                <Button variant="text" color="primary"
                  endIcon={
                    <Box display="flex" width="20px" height="20px">
                      <img src={ArrowImage} width={"100%"} height={"100%"} />
                    </Box>
                  }
                  style={{ paddingLeft:"1rem" }}
                  disableRipple={true}
                >
                <Typography
                    variant="subtitle2"
                    style={{...webStyles.fontBold,color:"#BB393D"}}
                  >
                    Learn More
                  </Typography>
                </Button>
                </CardContent>
              </ImageCard>
            </Grid>
            )):(
              <CenterTypographyTwo>There is no Data...!</CenterTypographyTwo>
            )}
          </Grid>
    );
  };

  renderExplorePolularFieldsOfStudey = () => {
    return (
      <Box sx={webStyles.fieldsOfStudiesMainBox}>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12} justifyContent="center">
              <Typography
                variant="h3"
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin:'20px 0px 0px 20px'
                }}
              >
                {configJSON.labelExplorePopularfieldofStudy}
              </Typography>
            </Grid>
            {this.state.explorePopularFieldOfstudy.map((i) => (
              <Grid item xs={6} sm={4} md={3}>
                <Card style={{ maxWidth: "auto", height: "260px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",backgroundColor:"white" }}>
                  <CardMedia
                    style={{
                      height: "166px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "10px",
                    }}
                  >
                    <Avatar
                      src={i.imageURL}
                      style={{
                        borderRadius: "50%",
                        width: "auto",
                        height: "166px",
                      }}
                      alt="course Name"
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {i.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  };

  renderExplorePopularFieldsOfStudyFlag = () => {
    return (
      <Box style={{ padding: "5rem 0"}}>
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} justifyContent="center" style={{paddingBottom:"3rem"}}>
              <CenterTypography>
                {configJSON.labelExplorePopularfieldofStudy}
              </CenterTypography>
            </Grid>
            <Grid
                container
                style={{gap:"40px"}}
                justifyContent="center"
                alignItems="center"
              >
            {this.state.flagData.map((flag) => (
              <Grid item xs={12} md={3} sm={6} lg={2} key={flag.id}>
                <Box style={webStyles.justifyCenter}>
                <ImageCardTwo>
                  <UnderImageBox width="230px" height="100px">
                        <img src={flag.logoImg} width="100%" height="100%" />
                        <GradiantColorBox
                         width="100%"
                         height="100%"
                         />
                      </UnderImageBox>
                      <Box style={{height:"full",display:"flex",flexDirection:"column",justifyContent:"space-between",alignItems:"flex-start"}}>
                    <TextLeftBold
                    style={{fontSize:"1.25rem"}}
                    >
                      {flag.title}
                    </TextLeftBold>
                  <Button
                    variant="text"
                    color="primary"
                    endIcon={
                      <Box display="flex" width="20px" height="20px">
                        <img src={ArrowImage} width="100%" height="100%" />
                      </Box>
                    }
                    disableRipple={true}
                    style={{ paddingLeft:"1rem" }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{...webStyles.fontBold,color:"#BB393D"}}
                    >
                      Learn More
                    </Typography>
                  </Button>
                  </Box>
                </ImageCardTwo>
                </Box>
              </Grid>
            ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  renderStepIndicatorSection = () => {
    return (
      <Box sx={webStyles.stepIndicatorBox}>
        <Container maxWidth="lg">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              justifyContent="center"
              container
              direction="column"
              alignItems="center"
            >
              <Typography style={{ textAlign: "center",margin:"15px 0px 0px 0px" }} variant="h2">
                {configJSON.textLabel1}
              </Typography>
              <Typography
                style={{ textAlign: "center", width: "50%",margin:"10px 0px 0px 0px" }}
                variant="subtitle2"
              >
                {configJSON.textlabel11}
              </Typography>
            </Grid>
            {this.state.stepData.map((item) => (
              <>
                <Box
                 sx={webStyles.stepOuterCircleBox}
                >
                  <Box
                   style={webStyles.stepInnerCircleBox}
                  >
                    <img
                      src={RightTick}
                      width="30%"
                      height="30%"
                      style={{ justifyContent: "center", alignItems: "center" }}
                    />
                  </Box>
                  <Box sx={webStyles.stepTypographyBox}>
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        textTransform: "capitalize",
                        fontWeight: "700",
                        margin:'2px 0px 0px 0px'
                      }}
                    >
                      {item.stepTitle}
                    </Typography>
                    <Typography      
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        color: "#34D399",margin:'5px 20px 20px 0px',
                        fontSize:'14px'
                      }}
                    >
                      {item.stepDescription}
                    </Typography>
                  </Box>
                </Box>
              </>
            ))}
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ margin: "40px 0px 0px 0px" }}
            >
              <Divider orientation="horizontal" color="red" variant="middle" />
              <CommanButton
                style={{ color: "white", backgroundColor: "#34D399" }}
              >
                {configJSON.btnLabelExplorenow}
              </CommanButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  renderWeConnectSchoolStudentWorld = () => {
    return (
      <Box style={webStyles.headerDiv}>
        <Container maxWidth="lg">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} justifyContent="center">
              <Typography
                variant="h3"
                style={{textAlign: "center",fontSize:"1.875rem"}}
              >
                {configJSON.textLabelString}
              </Typography>
            </Grid>
            <Grid
              xs={6}
              sm={3}
              md={3}
              item
              container
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography
                  variant="h2"
                  style={{ color: theme.palette.primary.main }}
                >
                  {configJSON.textValue1}
                </Typography>
                <Typography variant="h6">
                  {configJSON.textLabelStudentHelped}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="h3"
                  style={{ color: theme.palette.primary.main }}
                >
                  {configJSON.textValue2}
                </Typography>
                <Typography variant="h6">
                  {configJSON.textLabelProgram}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={6}
              item
              justifyContent="center"
              alignItems="center"
            >
              <Box
                style={{
                  justifyContent: "center",
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <img src={TourImage} width="auto" height={350} />
              </Box>
            </Grid>
            <Grid
              xs={6}
              sm={3}
              md={3}
              item
              container
              direction="column"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography
                  variant="h3"
                  style={{ color: theme.palette.primary.main }}
                >
                  {configJSON.textValue3}
                </Typography>
                <Typography variant="h6">
                  {configJSON.labelbtnRecruitmentPartner}
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  variant="h3"
                  style={{ color: theme.palette.primary.main }}
                >
                  {configJSON.textValue4}
                </Typography>
                <Typography variant="h6">
                  {configJSON.labelPartnerschool}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  renderAchieveYourStudyAboardDreams = () => {
    return (
        <AcheiveGoalsBox>
          <AcheiveGoalsGrid container>
            <Grid item xs={12} md={6} lg={6}>
              <AcheiveGoalsContentBox>
                <Box sx={{ mb: 3 }}>
                  <AcheiveGoalContainerHeader>
                    {configJSON.textLabel}
                  </AcheiveGoalContainerHeader>
                </Box>
                <Box sx={{ mb: 4}}>
                  <AcheiveGoalSubHeader>
                    {configJSON.labelTextSubHeadingSection2}
                  </AcheiveGoalSubHeader>
                </Box>
                <Box>
                  <Button style={{border:"1px solid white",borderRadius:"8px",gap:"8px"}}>
                    <AcheiveGoalButton variant="button">
                      {configJSON.btnLabelGetPersionalizedStudyAbrooadOption}
                    </AcheiveGoalButton>
                  </Button>
                </Box>
              </AcheiveGoalsContentBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={webStyles.justifyCenter}>
              <AcheiveImageBox>
                <img src={TravelImage} width={"100%"} height={"100%"}/>
              </AcheiveImageBox>
            </Grid>
          </AcheiveGoalsGrid>
        </AcheiveGoalsBox>
    );
  };

  renderUnivercityLogo = ()=>{
    return(
      <Box style={{ padding: "5rem 0",
    background: "#F7F7F7"}}>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{gap:"80px"}}
        >
          <Grid item xs={12}>
            <UniversityLogoBox>
              {configJSON.labeltitleExploreInstitution}
            </UniversityLogoBox>
          </Grid>
          <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          style={{gap:"32px"}}
        >            {this.state.allCountryName.map((country,index)=>
          country.attributes.id === this.state.activeId 
             ? <ActiveButton data-test-id="activeButtonId" key={country.id} style={{textTransform:"uppercase"}} onClick={()=>this.filterBelowCardData(country.attributes.id)}>{country.attributes?.country_name?.toUpperCase()}</ActiveButton>
             : <LoginButton data-test-id={`deActiveButtonId${index}`} key={country.id} style={{textTransform:"uppercase"}} onClick={()=>this.filterBelowCardData(country.attributes.id)}>{country.attributes?.country_name?.toUpperCase()}</LoginButton>
              )}
              </Grid>
          <Grid container
          direction="row"
          justifyContent="center"
          alignContent="center">
          {Array.isArray(this.state.belowCardData.recruiter) && this.state.belowCardData.recruiter.length>0?this.state.belowCardData.recruiter.map((dataCard,index) => (
            (index <= 7 || this.state.showAllUniversitylogo) &&(<Grid item xs={12} sm={6} md={3} style={{...webStyles.justifyCenter,padding:"1rem"}}>
              <LogoImageBox>
              <img src={dataCard.attributes?.url?.url} width={'100%'} height={'100%'}/>
              </LogoImageBox>
            </Grid>)
          )):(
            <CenterTypographyTwo>
There is no Data...! </CenterTypographyTwo>
          )}
        </Grid>
        <Grid item xs={12} style={{display:"flex"}} justifyContent="center">
       {Array.isArray(this.state.belowCardData.recruiter) && this.state.belowCardData.recruiter.length > 8 &&
       <RegisterButton data-test-id="showUniversitylogoId" onClick={this.showUniversityLogo}>Explore Institutions</RegisterButton>}
        </Grid>
        </Grid>
      </Container>
    </Box>
    )
  }

  aboutCardsSchool = () => {
   return (
    <Box style={{padding:"5rem 0"}}>
    <CenterTypography style={{marginBottom:"5rem",margin:"auto",width:"80vw"}}>
    An Easy-to-Use Platform that Connects You to the Right School
    </CenterTypography>
    <Grid container style={webStyles.justifyCenter}>
        {this.state.aboutRightSchoolData.map((cardData,index)=>(
      <Grid key={index} item xs={12} sm={6} md={6} lg={3} style={{display:"flex",marginTop:"2rem"}} justifyContent="center">
        <SchoolAboutCard>
        <SchoolAboutBox>
          <SchoolAboutIconBox>
            <img src={cardData.icon} alt="loading..." width="100%" height="100%" />
          </SchoolAboutIconBox>
          <Box>
            <CardDescription style={{lineHeight:"28px",letterSpacing:"1.5px"}}>{cardData.title}</CardDescription>
          </Box>
        </SchoolAboutBox>
        </SchoolAboutCard>
      </Grid>
    ))}
    </Grid>
    </Box>
   )
  }
  renderGridCards = () => {
    return (
      <Box style={{padding:"5rem 0",
      background: "#F7F7F7",width:"100%"
    }}>
      <CenterTypography style={{marginBottom:"5rem"}}>
      We are Invested in Caring for You and the Ecosystem of International Education
      </CenterTypography>
      <Grid container style={{...webStyles.justifyCenter,display:"flex"}} justifyContent="center">
      {Array.isArray(this.state.belowCardData.student) && this.state.belowCardData.student.length>0?this.state.belowCardData.student.map((cardData)=>(
        <Grid item xs={12} sm={6} md={6} lg={4} style={{margin:10,display:"flex"}} justifyContent="center">
          <SchoolBottomCard>
          <SchoolBottomBox>
              <SchoolBottomimageBox src={cardData.attributes.url?.url} alt="loading..." width="100%" height="100%" style={{borderRadius:"50%"}}/>
            <Box>
              <CardTitle>{cardData.attributes.title.length>50 ? cardData.attributes.title.slice(0,50) + "..." : cardData.attributes.title }</CardTitle>
              <CardDescription>{cardData.attributes.description.length>50 ? cardData.attributes.description.slice(0,50) + "..." : cardData.attributes.description }</CardDescription>
            </Box>
          </SchoolBottomBox>
          </SchoolBottomCard>
        </Grid>
      )):(
        <CenterTypographyTwo>   There is no Data...! 
              </CenterTypographyTwo>
      )}
      </Grid>
      </Box>
    )
  }
  defaultSectionLandingPage = () =>{
    return(
      <>         
      {this.RecruiterBanner()}
      {this.renderGetStartedApplyBoard()}
      {this.renderAchieveYourStudyAboardDreams()}
      {this.renderExplorePopularFieldsOfStudyFlag()}
      {this.renderUnivercityLogo()}
        </>
    )
  }

  handleRender = ()=>{
      switch(this.state.showSchool){
      case 'school':
        return(
          <>
              {this.SchoolBanner()}
              {this.ServicesEverythingInonePlace()}
              {this.aboutCardsSchool()}
              {this.renderGridCards()}
          </>
          )

      default:
        return(
          <>
          {this.defaultSectionLandingPage()}
          </>
          )        
    }
  }

  // Customizable Area End

    render() {
        return (
      // Customizable Area Start
      <>
        <AppHeader isLogin={true}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
        />
        {this.handleRender()}    
         <Footer onAnyPageNavigate={this.navigateToAnyPage}/>      
      </>
      // Customizable Area End
        );
    }
}

// Customizable Area Start

const CommanButton = styled(Button)({
  borderRadius: "8px",width: "auto",
  height: "44px",gap: "8px",
  background: "#BB393D",color: "#FFFFFF",
  fontFamily: "Plus Jakarta Sans",
  textTransform: "unset",
  "&:hover": {
    background: "#dcdee0",color: "#000",
    }
});

const BtnNewBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  justifyContent: "center",
  alignItems:"center"
});

const BtnNewBoxLoginBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginTop: "30px",
  width: "200px",
  padding: "0px 20px",
  height: "90px",
  justifyContent: "space-between"
});

const NavbarApp = styled(Box)({
  display: "none",
  "@media(max-width: 992px)": {
    display: "block"
  },
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff",
    boxShadow: "none"
  },
  "& .menuItem": {
    color: "#B6373C"
  }
});

const HeaderTop = styled(Box)({
  "media(max-width: 992px)": {
    display: "flex",
    flexDirection: "column",justifyContent:"center",
  }
});

const LoginBtn = styled(Button)({
  border: "2px solid #BB393D",
  height: "40px",
  fontWeight: 600,
  borderRadius: "8px",
  color: "#BB393D",
  padding: "0px 15px",
  textTransform: "unset"
});

const SingupBtn = styled(Button)({
  backgroundColor: "#BB393D",
  color: "#fff",
  height: "40px",
  fontWeight: 600,
  borderRadius: "8px",
  padding: "0px 15px",
  textTransform: "unset",
  "&.MuiButton-root:hover": {
    backgroundColor: "#BB393D"
  }
});

const CustomDrawer = styled(Drawer)({
  padding: "0px 40px",
});

const CloseIcon = styled(Close)({
  margin: "20px",
  cursor: "pointer"
});

const SchoolBottomCard = styled(Card)({
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  borderRadius:"8px",
  padding:"20px",
  "@media (max-width:960px)": {
    width:"144px",height:"286px",
  },
  "@media (min-width:720px)": {
    width:"400px",height:"200px",
  }   
})

const SchoolAboutCard = styled(Card)({
  borderRadius:"12px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    width:"240px",height:"260px",
    fontFamily: "Plus Jakarta Sans",
})

const SchoolBottomimageBox = styled("img")({
  width:"96px",height:"96px",
  fontFamily: "Plus Jakarta Sans",
});

const SchoolAboutIconBox = styled(Box)({
  width:"72px",height:"72px",
  fontFamily: "Plus Jakarta Sans",
});

const CardTitle = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontWeight:"bold",fontSize:"1.125rem",textAlign:"center"
  },
"@media (min-width:720px)": {
  fontWeight:"bold",fontSize:"1.125rem"}   
});

const CardDescription = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    textAlign:"center"
    },
  "@media (min-width:720px)": {
  textAlign:"start"
}   
});

const SchoolBottomBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  display:"flex",justifyContent:"space-evenly",alignItems:"center",width:"100%",height:"100%",gap:"24px",
    "@media (max-width:960px)": {
      flexDirection:"column"
},
  "@media (min-width:720px)": {
    flexDirection:"row"
  }   
})

const SchoolAboutBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  display:"flex",justifyContent:"space-evenly",alignItems:"center",width:"100%",height:"100%",
      flexDirection:"column"
})

const NavbarHeaderButton = styled(Button)({
  width:"auto",height:"2.75rem",fontFamily:"Inter",
  fontWeight:"bold",textTransform:"none"
})

const ImageCard = styled(Card)({
  fontFamily: "Plus Jakarta Sans",
  height:"24.438rem",
  border: "1px solid #94A3B8B2",
  "@media (max-width:960px)": {
    width:"17.25rem",
  },
  "@media (min-width:720px)": {
    width:"21.563rem",
  }   
});

const ImageCardTwo = styled(Card)({
  fontFamily: "Plus Jakarta Sans",
  width:"14.375rem",
  height:"200px",
  boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px" 
});

const UnderImageBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  background: "linear-gradient(180deg, rgba(187, 57, 61, 0) 0%, #BB393D 100%)",
  position:"relative"
});

const GradiantColorBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  background: "linear-gradient(180deg, rgba(187, 57, 61, 0) 0%, #BB393D 100%)",
  position:"absolute",
  bottom:0,
  left:0
})
const LoginGrid = styled(Box)({
  "@media (max-width:960px)": {
    display: "none",
  },
  "@media (min-width:992px)": {
    display: "flex",
    justifyContent:"space-between",
  }
});

const LoginGridContainer = styled(Box)({
    display: "flex",
    justifyContent:"space-between",
    width:"100%",
    alignItems:"center"
});

const AcheiveImageBox = styled(Box)({
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    justifyContent: "center",
    display: "flex",
    width:"12.5rem",height:"12.5rem" 
    },
  "@media (min-width:720px)": {
    justifyContent: "flex-end",
    display: "flex",
    width:"31.25rem",height:"31.25rem" 
    }
});

const AcheiveGoalContainerHeader = styled(Typography)({
  fontWeight:"bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize:"1.125rem",
  },
  "@media (min-width:720px)": {
    fontSize:"2.25rem",
  }
})

const AcheiveGoalSubHeader = styled(Typography)({
  "@media (max-width:960px)": {
    fontSize:"0.75rem",
  },
  "@media (min-width:720px)": {
    fontSize:"1rem",
  }
})

const AcheiveGoalButton = styled(Typography)({
  color:"white",fontWeight:"bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize:"0.75rem",
  },
  "@media (min-width:720px)": {
    fontSize:"1rem",
  }
})

const Banner = styled(Box)({
  display: "flex",justifyContent: "center",
  alignItems: "center",minHeight: 645,
  fontFamily: "Plus Jakarta Sans",
  "@media (min-width:960px)": {
    height:600,
  },
  "@media (min-width:600px)": {
    height: 300,
  },
  
});

const BannerUpDiv = styled(Box)({
  height: "640px",
  width: "100%", 
  position: "absolute",
  opacity: 0.8,
  fontFamily: "Plus Jakarta Sans",
  borderRadius:"8px"
});

const AcheiveGoalsBox = styled(Box)({
  color:"white",
  paddingTop:"5rem",
  width:"100%",
  fontFamily: "Plus Jakarta Sans",
  marginBottom:"2rem"
});

const AcheiveGoalsGrid = styled(Grid)({
  display:"flex",
  justifyContent:"center",
  padding:"2rem",
  fontFamily: "Plus Jakarta Sans",
  background: "linear-gradient(270deg, #611E20 0%, #BB393D 100%)"
});

const AcheiveGoalsContentBox = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Plus Jakarta Sans",
  justifyContent: "center",
  alignItems:"flex-start",
});

const ImageBoxTextHeader = styled(Typography)({
  fontSize:"24px",
  fontWeight:'bold',
  textAlign:'left',
  fontFamily: "Plus Jakarta Sans",
  letterSpacing:1,
});

const ImageBoxTextDescription = styled(Typography)({
  fontSize: "14px",
  fontWeight: "normal",
  textAlign: "left",
  fontFamily: "Plus Jakarta Sans",
  letterSpacing:1,
});

const BannerTxtDiv = styled(Box)({
  position: "relative",
      top: 0,
      left: 0,
    fontFamily: "Plus Jakarta Sans",
      width:"100%",
      height:"640px",
      backgroundImage: "linear-gradient(to right, #BB393D, transparent)",
});

const BannerHeader = styled(Typography)({
  color: "white",
  fontWeight:"bold"
  ,paddingLeft:"10vw",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize:"24px",width:"75vw",
  lineHeight:"28px",

  },
  "@media (min-width:720px)": {
    fontSize:"48px",width:"531px",
  lineHeight:"56px",
  } 
})
const BannerSubHeader = styled(Typography)({
  color: "white"
  ,paddingLeft:"10vw",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize:"0.8rem",width:"75vw",
  lineHeight:"12px",
  },
  "@media (min-width:720px)": {
    fontSize:"1rem",width:"531px",
  lineHeight:"24px",
  } 
})
const CenterTypography = styled(Typography)({
  width:"100%",
  textAlign: "center",
  fontSize:"1.875rem",
  fontFamily:"Plus Jakarta Sans",
  fontWeight:"bold",
  "@media (max-width:960px)": {
    fontSize:"1.4rem",
  },
  "@media (min-width:720px)": {
    fontSize:"1.875rem",
  }
});

const CenterTypographyTwo = styled(Typography)({
  width:"100%",
  textAlign: "center",
  fontFamily:"Plus Jakarta Sans",
  fontWeight:"normal",
  lineHeight:"28px",
  margin:"auto",
  "@media (max-width:960px)": {
    fontSize:"1rem",width:"100%"
  },
  "@media (min-width:720px)": {
    fontSize:"1.25rem",width:"645px"
  }
});

const TextLeftBold = styled(Typography)({
  paddingLeft:"1rem",
  marginBottom:"2rem",
    textAlign:"left",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:"bold"
});

const UniversityLogoBox = styled(Typography)({
  textAlign: "center",
  margin:"auto",
  fontSize:"1.875rem",
  fontFamily:"Plus Jakarta Sans",
  fontWeight:"bold",
  "@media (max-width:960px)": {
    fontSize:"1.4rem",
  width:"100%",
  },
  "@media (min-width:720px)": {
    fontSize:"1.875rem",
  width:"504px",
  }
})

const TextCenter = styled(Typography)({
  fontSize:"24px",
  textAlign: "center",
  fontWeight: "bold",
  fontFamily:"Plus Jakarta Sans"
});


const LogoImageBox = styled(Box)({
    width:"17.5rem",
    fontFamily: "Plus Jakarta Sans",
    height:"5rem"   
});

const LoginButton = styled(Button)({
  borderRadius: "8px",
  border: "2px solid",
  height:"2.75rem",
  marginRight:"2rem",
  padding:"10px 16px",
  borderColor: theme.palette.primary.main,
  whiteSpace:"nowrap",
  width:"auto",
  color:"#BB393D",
  fontWeight:"bold",
  fontFamily:"Plus Jakarta Sans",
  textTransform:"none"
});

const ActiveButton = styled(Button)({
  borderRadius: "8px",
  border: "2px solid",
  height:"2.75rem",
  marginRight:"2rem",
  padding:"10px 16px",
  borderColor: theme.palette.primary.main,
  whiteSpace:"nowrap",
  width:"auto",
  color:"#ffffff",
  backgroundColor:"#BB393D",
  fontWeight:"bold",
  fontFamily:"Plus Jakarta Sans",
  textTransform:"none",
  "&:hover": {
  backgroundColor:"#BB393D",
    },
});

const TabBox = styled(Box)({
  border: "1px solid #059669",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  borderRadius: "50px",
  fontFamily: "Plus Jakarta Sans",
  width:"fit-content",
 padding:"4px",
 "@media (max-width:960px)": {
  height:"2rem",
 gap:"20px",
},
"@media (min-width:820px)": {
  height:"3rem",
 gap:"96px",
}
});

const TabButton = styled(Button)({
  borderRadius: "50px",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    height:"2rem",
  },
  "@media (min-width:820px)": {
    height:"3rem",
  }
});

const TabTypography = styled(Typography)({
  whiteSpace:"nowrap",
  fontWeight:"bold",
  fontFamily: "Plus Jakarta Sans",
  "@media (max-width:960px)": {
    fontSize:"0.5rem",
  },
  "@media (min-width:720px)": {
    fontSize:"1rem",
  }
});

const RegisterButton = styled(Button)({
  borderRadius: "8px",
  height:"2.75rem",
  textTransform:"none",
  padding:"10px 16px",
  backgroundColor: theme.palette.primary.main,
  whiteSpace:"nowrap",
  width:"auto",
  color:"white",
   "&:hover": {
    background: "#dcdee0",
      color: "#000",
    },
    fontWeight:"bold",
    fontFamily:"Plus Jakarta Sans"
});

const webStyles = {
  stepIndicatorBox:{
    flex: 1,margin:"30px 0px 0px 0px",
  },
  justifyEnd:{ 
    justifyContent: "flex-end",display: "flex" 
    },
    justifyCenter:{ 
      justifyContent: "center",
       display: "flex" 
      },
  stepOuterCircleBox:{
    justifyContent: "center",alignItems: "center",
    display: "flex", flexDirection: "column",
    margin: "20px 0px 0px 0px",
  },
  fontBold:{
    fontWeight:"bold"
  },

  stepInnerCircleBox:{
    width: "54px",height: "54px",
    border: "1px solid #34D399",borderRadius: "50%",
    backgroundColor: "#34D399",justifyContent: "center",
    alignItems: "center",display: "flex",
  },
  stepTypographyBox:{
    margin: "10px 0px 0px 0px",alignContent:'center',
    justifyContent:'center'
  },
  getStartedBoardApplyMainBox:{
    flex:1,margin:"5rem 0"    
  },
  fieldsOfStudiesMainBox:{
    flex:1,
    margin:"50px 0px 0px 50px",
  },
  widthAndHeight:{
    width:'280px',height:'80px'
   },
  buttonBox:{
    justifyContent: "center",alignItems: "center",
    display: "flex",padding: "2px",
    gap: 50,margin:"50px 0px 0px 50"
  },
  renderHomeHeroBox:{
    background: "white",
    padding: "2rem 0",
    },
    heroSectionBox:{
      fontSize: 38,
      letterSpacing: 1.5,
      fontWeight: "700",
      lineHeight: 1.3,
      marginBottom:"2rem"
    },
   headerDiv:{
     display: "flex",
     backgroundColor: "#F7F7F7"
    }

}

// Customizable Area End