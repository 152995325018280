import { Box, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react'
import IconOne from './sidebarMenuIcons/IconOne';
import IconTwo from './sidebarMenuIcons/IconTwo';
import IconThree from './sidebarMenuIcons/IconThree';
import IconFour from './sidebarMenuIcons/IconFour';
import IconFive from './sidebarMenuIcons/IconFive';
import IconSix from './sidebarMenuIcons/IconSix';
import IconSeven from './sidebarMenuIcons/IconSeven';
import IconEight from './sidebarMenuIcons/IconEight';
import IconNine from './sidebarMenuIcons/IconNine';
import IconTenth from './sidebarMenuIcons/IconTenth';
import IconEleven from './sidebarMenuIcons/IconEleven';
import IconTwelve from './sidebarMenuIcons/IconTwelve';
import IconThirteen from './sidebarMenuIcons/IconThirteen';
import IconFourteen from './sidebarMenuIcons/IconFourteen';
import IconFifteen from './sidebarMenuIcons/IconFifteen';

const tempData = [
    {
        componentName:"DashBoard",
        icon:IconOne,
        header:"Dashboard"
    },
    {
        componentName:"",
        icon:IconTwo,
        header:"Programs & Schools"
    },
    {
        componentName:"",
        icon:IconThree,
        header:"Wallet"
    },
    {
        componentName:"",
        icon:IconFour,
        header:"Training request"
    },
    {
        componentName:"LeadManagement",
        icon:IconFive,
        header:"Students"
    },
    {
        componentName:"RequestManagementTableWeb",
        icon:IconSix,
        header:"Applications"
    },
    {
        componentName:"",
        icon:IconSeven,
        header:"Payment"
    },
    {
        componentName:"",
        icon:IconEight,
        header:"Promotional Commisions and incentives"
    },
    {
        componentName:"",
        icon:IconNine,
        header:"Manage Staff"
    },
    {
        componentName:"",
        icon:IconTenth,
        header:"Teams"
    },
    {
        componentName:"",
        icon:IconEleven,
        header:"GIC  Account (New)"
    },
    {
        componentName:"",
        icon:IconTwelve,
        header:"ILELTS Test  booking (New)"
    },
    {
        componentName:"",
        icon:IconThirteen,
        header:"Reminder"
    },
    {
        componentName:"",
        icon:IconFourteen,
        header:"Blank Application Forms and documents"
    },
    {
        componentName:"",
        icon:IconFifteen,
        header:"About Updates"
    }
]

interface SideMenuBarPropsType {
    activeComponent:string;
    navigationToAnyPage:(pageName: string) => void;
}
export default class SideMenuBar extends Component<SideMenuBarPropsType> {
    state = {
      openDrawer:false
    }

    changeDrawerState = () => {
     this.setState({openDrawer: !this.state.openDrawer})
    }

    navigateToPage = (pageName:string) => {
      if(pageName) {
        this.props.navigationToAnyPage(pageName)
         this.setState({openDrawer: false})
      }
    }

    renderDrawer = () => {
        return (
<SideMenuBarBigBox>
  {tempData.map((item, index) => (
    <DrawerBox key={index} style={{
    backgroundColor: item.componentName === this.props.activeComponent ?  "#34D3994D" : "white"}}
    onClick={()=> this.navigateToPage(item.componentName)}>
    <IconBoxBigSize title={item.header}>
              <item.icon color={item.componentName === this.props.activeComponent ?  "#34D399" : "black"}/>
    </IconBoxBigSize>
      <IconTxt style={{color:item.componentName === this.props.activeComponent ?  "#34D399" : "black"}}>
        {item.header}
      </IconTxt>
    </DrawerBox>
  ))}
  <DrawerBigBoxIcon onClick={this.changeDrawerState}>
    {"<"}
  </DrawerBigBoxIcon>
</SideMenuBarBigBox>
        )
    }

    renderSmallScreen = () => {
        return (
<SideMenuBarBox>
  {tempData.map((item, index) => (
    <IconBoxSmallSize
    key={index} style={{
    backgroundColor: item.componentName === this.props.activeComponent ?  "#34D3994D" : "white"}}
     title={item.header} onClick={()=>this.navigateToPage(item.componentName)}>
              <item.icon color={item.componentName === this.props.activeComponent ?  "#34D399" : "black"}/>
    </IconBoxSmallSize>
  ))}
  <DrawerBoxIcon onClick={this.changeDrawerState}>
    {">"}
  </DrawerBoxIcon>
</SideMenuBarBox>
        )
    }

    render() {
        return (
          <MainBox >
            {this.state.openDrawer ? this.renderDrawer() : this.renderSmallScreen()}
          </MainBox>
        )
    }
}

const MainBox = styled(Box)({
  display: "flex",
  width: "64px",
  backgroundColor: 'white',
  borderRight: '1px solid #E2E8F0',
  boxShadow: '1',
  top: 85,
  bottom: 0,
  overflowY: "auto",
  position: "fixed",
  zIndex: 1000,
     '::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
      display: 'none',
    },
    scrollbarWidth: 'none',
});

const SideMenuBarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  backgroundColor: 'white',
  alignItems: 'center',
  padding: "10px 0px",
  width: "100%"
})

const SideMenuBarBigBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignItems: 'center',
    boxShadow: '1',
    position: 'fixed',
    borderRight: '1px solid #E2E8F0',
  padding: "10px 0px",
  maxHeight: "80%",
  overflowY: "scroll",
      '@media (max-width:960px)': {
      width: '250px',
    },
    '@media (min-width:720px)': {
      width: '306px',
    },
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
      display: 'none',
    },
    scrollbarWidth: 'none',
  });
  

const IconBoxBigSize = styled(Box)({
    cursor:"pointer",
    width:"32px",
    margin:"0 4px"
})

const IconBoxSmallSize = styled(Box)({
    cursor:"pointer",
    width:"32px",
    margin:"5px 0",
    borderRadius:"6px",
    padding:"2px",
})

const DrawerBox = styled(Box)({
    margin:"10px 0",
    width:"230px",
    display:"flex",
    alignItems:"center",
    padding:"5px",
    borderRadius:"6px",
    cursor:"pointer",
})

const IconTxt = styled(Typography)({
    margin:"0 4px",
    fontWeight:500,
    fontFamily:"Plus Jakarta Sans",
    fontSize:"14px",lineHeight:"17.64px",
})

const DrawerBoxIcon = styled(Box)({
    width:"24px",
    height:"24px",
    position:"fixed",
    fontWeight:900,
    cursor:"pointer",
    boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    background:"white",
    borderRadius:"20%",
    display:"flex",
    justifyContent:"center",
    top:"50%",
        left:"50px",
        fontFamily: 'Plus Jakarta Sans'
})

const DrawerBigBoxIcon = styled(Box)({
    width:"24px",
    height:"24px",
    position:"fixed",
    fontWeight:900,
    cursor:"pointer",
    transform: "translateY(-50%)",
    boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    background:"white",
    borderRadius:"20%",
    display:"flex",
    justifyContent:"center",
    fontFamily: 'Plus Jakarta Sans',
    '@media (max-width:960px)': {
      top:"50%",
      left:"240px",
    },
    '@media (min-width:720px)': {
      top:"50%",
      left:"295px",
    },
})