import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconSix extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<rect width="32" height="32" rx="6" fill="none" fillOpacity="0.3"/>
<path d="M26 14V19C26 24 24 26 19 26H13C8 26 6 24 6 19V13C6 8 8 6 13 6H18" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M26 14H22C19 14 18 13 18 10V6L26 14Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 17H17" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11 21H15" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



