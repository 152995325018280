import React from "react";
// Customizable Area Start
import {
  Box,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import DasbordViewMoreController from "./DasbordViewMoreController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import { Card, Grid, TextField, Typography } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End

export default class DasbordViewMore extends DasbordViewMoreController {
  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DasbordViewMoreStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
          />
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="LeadManagement" />

          <Box className="mainBar">
            <BoxButton>
              <Box><StudentTxt >{configJSON.recentUpdateTitle}</StudentTxt></Box>
              <Box>
                <TextField
                  data-test-id="travelDateId"
                  // value={visa.date}
                  // onChange={(event) => }
                  className="DatePicker"
                  variant="outlined"
                  size="small"
                  type="date"
                  placeholder={configJSON.selectStudentAddedByPlaceholder}
                // inputProps={{ min: minDate, max: maxDate }}
                />
              </Box>
            </BoxButton>
            <BoxButton>
              <Box className="BoxContainer">
                {
                  this.paginateData(this.state.CardDetailsData,this.state.page,4).map((item, index) => {
                    return (
                      <Card className="CardContainer" data-testid={`UpadtedListItem`+index} key={index + "12"}>
                        <Grid container>
                          <Grid item xs={3}
                            className="ImageContainer">
                            <Box>
                              <img src={item.imageUrl} className="CardImage" />
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <div className="ContentContainer">
                              <div className="HeadingContainer">
                                <Typography variant="h5" className="Heading">{item.heading}</Typography>
                                <Typography variant="subtitle1">Posted on {item.PublishDate}</Typography>
                              </div>
                              <div>
                                <Typography
                                  variant="subtitle2"
                                  gutterBottom
                                  dangerouslySetInnerHTML={this.SetInnteHtml(item.points)}
                                  color="textSecondary" />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  })
                }
                <Box className="PaginationContainer" >
                  <Pagination
                    data-test-id="countTestId"
                    count={this.renderCount()}
                    page={this.state.page}
                    onChange={this.handleChangePage}
                  />
                </Box>
              </Box>
            </BoxButton>
          </Box>
        </LeadBox>
      </DasbordViewMoreStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const DasbordViewMoreStyle = styled(Box)({
  "& .BoxContainer": {
    width: "100%"
  },
  "& .CardContainer": {
    width: "100%",
    display: "flex",
    padding: "20px 5px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  "& .CardImage": {
    width: "150px",
    borderRadius: "200px",
    height: "150px",
    maxHeight: '200px'
  },
  "& .ImageContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .ContentContainer": {
    padding: "10px 0px",
    paddingRight: "20px",
    "& .HeadingContainer": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px",
      marginTop: "20px",
      "& .Heading": {
        color: "#400a9d",
      }
    }
  },
  "& .PaginationContainer": {
    display: "flex",
    justifyContent: "center",
  }
})

const BoxButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  margin: "20px 0px",
  "@media (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "10px"
  }
})

const StudentTxt = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px !important",
  fontWeight: '700 !important'
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    marginLeft: "64px",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    }
  },
});
// Customizable Area End