import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconFifteen extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M13.3203 15.1799L15.8803 17.7399L18.4403 15.1799" stroke={this.props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.8789 7.5V17.67" stroke={this.props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24 15.6799C24 20.0999 21 23.6799 16 23.6799C11 23.6799 8 20.0999 8 15.6799" stroke={this.props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



