import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconEight extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M13 5.5H19C24 5.5 26 7.5 26 12.5V18.5C26 23.5 24 25.5 19 25.5H13C8 25.5 6 23.5 6 18.5V12.5C6 7.5 8 5.5 13 5.5Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5703 18.77L19.1103 12.23" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.98 13.8699C13.6593 13.8699 14.21 13.3192 14.21 12.6399C14.21 11.9606 13.6593 11.4099 12.98 11.4099C12.3007 11.4099 11.75 11.9606 11.75 12.6399C11.75 13.3192 12.3007 13.8699 12.98 13.8699Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.519 19.5899C20.1984 19.5899 20.7491 19.0392 20.7491 18.3599C20.7491 17.6806 20.1984 17.1299 19.519 17.1299C18.8397 17.1299 18.2891 17.6806 18.2891 18.3599C18.2891 19.0392 18.8397 19.5899 19.519 19.5899Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



