import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconSeven extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M12.6719 17.8298C12.6719 19.1198 13.6619 20.1598 14.8919 20.1598H17.4019C18.4719 20.1598 19.3419 19.2498 19.3419 18.1298C19.3419 16.9098 18.8119 16.4798 18.0219 16.1998L13.9919 14.7998C13.2019 14.5198 12.6719 14.0898 12.6719 12.8698C12.6719 11.7498 13.5419 10.8398 14.6119 10.8398H17.1219C18.3519 10.8398 19.3419 11.8798 19.3419 13.1698" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 9.5V21.5" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19 25.5H13C8 25.5 6 23.5 6 18.5V12.5C6 7.5 8 5.5 13 5.5H19C24 5.5 26 7.5 26 12.5V18.5C26 23.5 24 25.5 19 25.5Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



