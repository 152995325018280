import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconOne extends Component<IconPropsType> {
      
    render() {
        return (
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8.23 26.25C6.22 26.25 5.25 25.32 5.25 23.4V7.6C5.25 5.68 6.23 4.75 8.23 4.75H12.27C14.28 4.75 15.25 5.68 15.25 7.6V23.4C15.25 25.32 14.27 26.25 12.27 26.25H8.23ZM6.75 7.6V23.4C6.75 24.41 6.96 24.75 8.23 24.75H12.27C13.54 24.75 13.75 24.41 13.75 23.4V7.6C13.75 6.59 13.54 6.25 12.27 6.25H8.23C6.96 6.25 6.75 6.59 6.75 7.6Z" fill={this.props.color}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M19.73 14.75C17.72 14.75 16.75 13.86 16.75 12.02V7.48C16.75 5.64 17.73 4.75 19.73 4.75H23.77C25.78 4.75 26.75 5.64 26.75 7.48V12.01C26.75 13.86 25.77 14.75 23.77 14.75H19.73ZM18.25 7.48V12.01C18.25 12.87 18.39 13.24 19.73 13.24H23.77C25.11 13.24 25.25 12.86 25.25 12.01V7.48C25.25 6.62 25.11 6.25 23.77 6.25H19.73C18.39 6.25 18.25 6.63 18.25 7.48Z" fill={this.props.color}/>
  <path fillRule="evenodd" clipRule="evenodd" d="M19.73 26.25C17.72 26.25 16.75 25.27 16.75 23.27V19.23C16.75 17.22 17.73 16.25 19.73 16.25H23.77C25.78 16.25 26.75 17.23 26.75 19.23V23.27C26.75 25.27 25.77 26.25 23.77 26.25H19.73ZM18.25 19.23V23.27C18.25 24.45 18.55 24.75 19.73 24.75H23.77C24.95 24.75 25.25 24.45 25.25 23.27V19.23C25.25 18.05 24.95 17.75 23.77 17.75H19.73C18.55 17.75 18.25 18.05 18.25 19.23Z" fill={this.props.color}/>
</svg>
        )
    }
}



