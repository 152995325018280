import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconTenth extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M13.0825 15.12C13.0325 15.11 12.9625 15.11 12.9025 15.12C10.0025 15.03 7.8125 12.75 7.8125 9.94C7.8125 7.08 10.1425 4.75 13.0025 4.75C15.8625 4.75 18.1925 7.08 18.1925 9.94C18.1825 12.75 15.9825 15.03 13.1925 15.12C13.1825 15.12 13.1725 15.12 13.1625 15.12C13.1325 15.12 13.1125 15.12 13.0825 15.12ZM9.3125 9.94C9.3125 11.94 10.8725 13.55 12.8625 13.62C12.9225 13.61 13.0525 13.61 13.1825 13.62C15.1425 13.53 16.6825 11.92 16.6925 9.94C16.6925 7.91 15.0325 6.25 13.0025 6.25C10.9725 6.25 9.3125 7.91 9.3125 9.94Z" fill={this.props.color}/>
<path d="M20.4523 15.24C20.0423 15.28 19.6223 14.99 19.5823 14.58C19.5423 14.17 19.7923 13.8 20.2023 13.75C20.3223 13.74 20.4523 13.74 20.5623 13.74C22.0223 13.66 23.1623 12.46 23.1623 10.99C23.1623 9.47 21.9323 8.24 20.4123 8.24C20.0023 8.25 19.6623 7.91 19.6623 7.5C19.6623 7.09 20.0023 6.75 20.4123 6.75C22.7523 6.75 24.6623 8.66 24.6623 11C24.6623 13.3 22.8623 15.16 20.5723 15.25C20.5623 15.25 20.5523 15.25 20.5423 15.25C20.5123 15.25 20.4823 15.25 20.4523 15.24Z" fill={this.props.color}/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.75156 24.55C6.36156 23.63 5.60156 22.37 5.60156 21C5.60156 19.63 6.36156 18.36 7.75156 17.43C10.7516 15.44 15.6116 15.44 18.5916 17.43C19.9716 18.35 20.7416 19.61 20.7416 20.98C20.7416 22.35 19.9816 23.62 18.5916 24.55C17.0916 25.55 15.1316 26.05 13.1716 26.05C11.2116 26.05 9.24156 25.55 7.75156 24.55ZM7.10156 21.01C7.10156 21.86 7.63156 22.68 8.58156 23.31C11.0716 24.98 15.2716 24.98 17.7616 23.31C18.7216 22.67 19.2416 21.85 19.2416 20.99C19.2416 20.14 18.7116 19.32 17.7616 18.69C15.2716 17.03 11.0716 17.03 8.58156 18.69C7.62156 19.33 7.10156 20.15 7.10156 21.01Z" fill={this.props.color}/>
<path d="M21.6082 23.65C21.5282 23.24 21.7882 22.85 22.1882 22.76C22.8182 22.63 23.3982 22.38 23.8482 22.03C24.4182 21.6 24.7282 21.06 24.7282 20.49C24.7282 19.92 24.4182 19.38 23.8582 18.96C23.4182 18.62 22.8682 18.38 22.2182 18.23C21.8182 18.14 21.5582 17.74 21.6482 17.33C21.7382 16.93 22.1382 16.67 22.5482 16.76C23.4082 16.95 24.1582 17.29 24.7682 17.76C25.6982 18.46 26.2282 19.45 26.2282 20.49C26.2282 21.53 25.6882 22.52 24.7582 23.23C24.1382 23.71 23.3582 24.06 22.4982 24.23C22.4382 24.25 22.3882 24.25 22.3382 24.25C21.9882 24.25 21.6782 24.01 21.6082 23.65Z" fill={this.props.color}/>
</svg>
        )
    }
}



