Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.requiredText = "Required";
exports.applicationsText = "Applications";
exports.testValidityText = "English Test Validity";
exports.studentIdText = "Student ID:106407";
exports.applicationIdText = "Application ID:82480";
exports.passportNoText = "Passport No:123456";
exports.universityIdText = "University ID:123456";
exports.nameText = "Gurneet Kaur";
exports.gicText = "Buy GIC";
exports.forexText = "Book Forex";
exports.accommodationText = "Find Accommodation";
exports.forexTravelCardText = "Forex Travel Card";
exports.travelInsuranceText = "Buy Student Travel Insurance";
exports.simCardText = "Sim Card";
exports.applyLoanText = "Apply Student Loan";
exports.numberText = "+91 8283091451";
exports.dateText = "12-July-1996";
exports.emailText = "gurneetkaur05042003@gmail.com";
exports.applicationReceivedText = "Application Received";
exports.universityNameText = "BPP University, London, United Kingdom";
exports.courseNameText = "MSc Management with Project Management";
exports.intakeText = "Intake:";
exports.intakeDateText = "Sep 2023";
exports.studentsAddText = "Students Address";
exports.studentsAddValueText = "25th Cross Rd, Jayanagar, Bangalore, Karnataka - 560070";
exports.emergencyContactText = "Emergency Contact Details";
exports.nameExampleText = "Name: Rajesh";
exports.emailAndPhoneText = "Email: rajesh@gmail.com Mobile:+91-9876543210";
exports.nationalityText = "Nationality";
exports.nationalityValueText = "Indian";
exports.residenceText = "Residence";
exports.residenceValueText = "Canada";
exports.createdOnText = "Created on:";
exports.createdOnDateText = "08-Nov-2023 03:39PM";
exports.createdByText = "Created by:";
exports.createdByNameText = "Dinesh Anand";
exports.lastActivityDateText = "Last Activity Date:";
exports.lastActivityDateValueText = "09-Nov-2023 03:39PM";
exports.agencyText = "Agency:";
exports.agencyValueText = "Aboard Study";
exports.prePaymentText = "Pre-Payment";
exports.lastReqText = "Last requirement completed on Sep. 20,2023";
exports.backlogLimitText = "Backlog Limit - Maximum 7";
exports.backlogSupportingText = "For Indian applicants residing in India:";
exports.copyPassText = "Copy of Passport";
exports.copyPassSupportText = "Please attach a copy of applicant's passport - pages that include applicant's identity information:";
exports.eduBackText = "Education Background";
exports.eduBackSupportText = "For Indian applicants residing in India and other South Asia Regions (Afghanistan, Bangladesh, Bhutan, Nepal, Sri Lanka, Pakistan) applicants residing in other South Asia Regions:";
exports.testValiditySupportText = "For Indian applicants residing in India:";
exports.preSubmissionText = "Pre-Submission";
exports.preSubmissionSupportText = "Please provide a copy of the applicant's test scores:";
exports.pteNameText = "(PTE) Pearson Test of English";
exports.pteNameSupportText = "Please provide a copy of the applicant's test scores:";
exports.backupProgText = "Backup/Alternative Programs";
exports.backupProgSupportText = "Please be advised that applicants are recommended to select Second/Third program choices.";
exports.zeroText = "0";
exports.fourText = "4";
exports.dividerText = "|";
// Customizable Area End