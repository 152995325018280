import { Box, Button, Grid, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react'
import * as IMG_CONST from "./assets";
import { setStorageData } from '../../../packages/framework/src/Utilities';
type MyProps = {
    onAnyPageNavigate: (pageName: string) => void;
  }
export default class Footer extends Component<MyProps> {

    navigateToAnyPage = (pageName:string) => {
      setStorageData("LandingPageActive",pageName)
        this.props.onAnyPageNavigate(pageName)
    }

    render() {
        return (
            <FooterMainBox>
                <Grid container spacing={3}>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4}>
                        <FirstBox >
                            <LogoContainer>
                                <FooterImg
                                    src={IMG_CONST.footerLogoImg}
                                />
                                <AddressTxt>101 Frederick St,Kitchener, ONN2H 6R3</AddressTxt>
                            </LogoContainer>
                        </FirstBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4}>
                        <CenterBox
                        >
                            <Box className="headBox"> <Typography>About</Typography></Box>
                            <ItemTxt>About Us</ItemTxt>
                            <ItemTxt onClick={()=>this.navigateToAnyPage("Contactus")}>Contact Us</ItemTxt>
                        </CenterBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={4} md={4} sm={4}>
                        <LastBox>
                            <Box className="headBox"><Typography>Legal</Typography></Box>
                            <ItemTxt>Privacy & Cookies Policy</ItemTxt>
                            <ItemTxt onClick={()=>this.navigateToAnyPage("TermsConditionsWeb")}>Terms & Conditions</ItemTxt>
                            <ItemTxt>ApplyBoard Fees</ItemTxt>
                            <ItemTxt>Modern Slavery Statement</ItemTxt>
                        </LastBox>
                    </FooterGrid>
                    <FooterGrid item xs={12} lg={3} md={3} sm={3}>
                        <GetSocialContainer>
                            <Box className="headBox"> <Typography>Get Social</Typography></Box>
                            <Box className="socialIconBox">
                                <SocialButton>
                                    <SocialImg
                                        src={IMG_CONST.image_Facebook}
                                    />
                                </SocialButton>
                                <SocialButton>
                                    <SocialImg
                                        src={IMG_CONST.button_Google}
                                    />
                                </SocialButton>
                                <SocialButton>
                                    <SocialImg
                                        src={IMG_CONST.button_LinkedIn}
                                    />
                                </SocialButton>
                                <SocialButton>
                                    <SocialImg
                                        src={IMG_CONST.button_Twitter}
                                    />
                                </SocialButton>
                                <SocialButton>
                                    <SocialImg
                                        src={IMG_CONST.button_Instagram}
                                    />
                                </SocialButton>
                            </Box>
                        </GetSocialContainer>
                    </FooterGrid>
                </Grid>
            </FooterMainBox>
        )
    }
}

const FooterMainBox = styled(Box)({
    background: "linear-gradient(90deg, #611E20 0%, #BB393D 100%)",
    padding: "30px 120px 40px 120px",
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Plus Jakarta Sans",
    "@media (max-width: 600px)": {
        padding: "30px 80px 40px 80px",
    },
    "@media (max-width: 400px)": {
        padding: "30px 40px 40px 40px",
    },
});

const FooterGrid = styled(Grid)({
    display: "flex",
    justifyContent: "center"
});

const ItemTxt = styled(Typography)({
    color: "#fff",
    textAlign: "center",
    margin: "0px 0px 5px 0px",
    cursor: "pointer"
});
const AddressTxt = styled(Typography)({
    maxWidth: "150px",
    minWidth: "50px",
    color: "#fff",
    fontSize: "14px",
    "@media(max-width:576px)": {
        maxWidth: "100%"
    }
});
const SocialImg = styled("img")({
    height: "20px",
    width: "20px",
}); const SocialButton = styled(Button)({
    minWidth: "0px",
    "&..MuiButton-text": {
        padding: "0px",
    },
    "&.MuiButton-root:hover": {
        backgroundColor: "transparent",
    },
});

const FooterImg = styled("img")({
    height: "80px",
    borderRadius: "15px",
    width: "80%",
    margin: "0px 0px 20px 0px"
});

const CenterBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& .MuiTypography-body1": {
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 400,
        fontStyle: "Plus Jakarta Sans"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 0px 15px 0px"
        }
    }
});
const FirstBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100%",
    "& .MuiTypography-body1": {
        color: "#fff",
        cursor: "pointer",
        margin: "5px",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Plus Jakarta Sans"
    },
    "@media(max-width:576px)": {
        alignItems: "center",
    }
});

const LogoContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    "@media(max-width:576px)": {
        alignItems: "center"
    }
})

const LastBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& .MuiTypography-body1": {
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: 400,
        fontStyle: "Plus Jakarta Sans"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 0px 15px 0px"
        }
    }
});

const GetSocialContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "2rem",
    "& .socialIconBox": {
        display: "flex",
        flexWrap: "nowrap"
    },
    "& .headBox": {
        "& .MuiTypography-body1": {
            fontSize: "22px",
            fontWeight: "bold",
            color: "#ffff",
            margin: "0px 0px 15px 0px"
        }
    },
    "@media(max-width:576px)": {
        width: "100%",
        alignItems: "center",
        marginTop: "0px",
        paddingLeft: "0px"
    }
})
