import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  TextField,
  Snackbar
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import * as IMG_CONST from "./assets";
import AppHeader from "../../../components/src/AppHeader.web";
import Alert from "@material-ui/lab/Alert";
import { configJSON } from "./EmailAccountLoginController";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  renderSetPswrd = () => {
    return (
      <MainBox>
        <FirstBox>
          <SetPswrdTxt >
            {configJSON.loginTxt}
          </SetPswrdTxt>
          <SetPsrwdInnerBox>
            <InputMainBox>
              <InputTxt>
                {configJSON.emailTxt}
                <StarTxt> {configJSON.starTxt}</StarTxt>
              </InputTxt>
              <InputField
                data-test-id="emailChangeTestId"
                placeholder={configJSON.emailTxtPlaceHolder}
                onChange={this.handleEmailChange}
                value={this.state.emailValue}
              />
              {this.state.emailError && (
                <RequiredTxt data-test-id="emailRequireTestId">{this.state.emailErrorTxt}</RequiredTxt>
              )}
            </InputMainBox>
            <InputMainBox>
              <InputTxt>
                {configJSON.pswrdTxt}
                <StarTxt> {configJSON.starTxt}</StarTxt>
              </InputTxt>
              <PswrdFieldBox>
                <PswrdField
                  placeholder={configJSON.pswrdTxtPlaceHolder}
                  value={this.state.pswrdValue}
                  data-test-id="pswrdChangeTestId"
                  onChange={this.handlePswrdChange}
                  type={this.state.visiblePswrd ? "password" : ""}
                />
                {this.state.visiblePswrd ? <VisibilityOffIcon data-test-id="visiblePswrdTestID" className="eye-icon" fontSize="small" onClick={this.visiblePswrd} /> : <VisibilityIcon className="eye-icon" fontSize="small" onClick={this.visiblePswrd} />}
              </PswrdFieldBox>

              {this.state.pswrdError && (
                <RequiredTxt data-test-id="passwordRequireTestId">{this.state.pswrdErrorTxt}</RequiredTxt>
              )}
            </InputMainBox>
            <SaveButton
              data-test-id="signUpBtnTestId"
              onClick={this.handleLogin}
            >
              {configJSON.loginTxt}
            </SaveButton>
            <ForgotBox>
              <ForgotTxt onClick={this.navigateToForgotPage} data-test-id="forgotPasswordTestID">{configJSON.forgetpswrd}</ForgotTxt>
            </ForgotBox>
          </SetPsrwdInnerBox>
          <LoginBox>
            <AlreadyTxt>
              {configJSON.alreadyTxt}
            </AlreadyTxt>
            <LoginButton onClick={this.navigateToRegistration}
              data-test-id="signupTestId">
              {configJSON.createAccountTxt}
            </LoginButton>
          </LoginBox>
        </FirstBox>
        <SecondBox>
          <SideImg src={IMG_CONST.sideImg} />
        </SecondBox>
      </MainBox>
    );
  };
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <Box>
        <AppHeader
          isLogin={false}
          onHomeClick={this.onHomeClick}
          onLoginClick={undefined}
          onSignUpClick={undefined}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          data-test-id="appHeaderTestId" 
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        {this.renderSetPswrd()}
      </Box>
       // Customizable Area End
    );
  }
}

// Customizable Area Start

const SetPsrwdInnerBox = styled(Box)({
  padding: "30px 0px",
  "@media (max-width: 600px)": {}
});

const ForgotTxt = styled(Typography)({
  color: "#0F172A",
  textAlign: "end",
  margin: "10px 0px",
  fontSize: "14px",
  cursor: "pointer"
});

const ForgotBox = styled(Box)({
  display: "flex",
  justifyContent: "end"
});

const LoginBox = styled(Box)({
  display: "flex",
  margin: "30px 0px",
  alignItems: "center",
  justifyContent: "center"
});

const AlreadyTxt = styled(Typography)({
  color: "#0F172A",
  fontSize: "16px",
  fontFamily: "Plus Jakarta Sans"
});

const LoginButton = styled(Typography)({
  color: "#BB393D",
  fontSize: "14px",
  fontWeight: 700,
  textTransform: "capitalize",
  fontFamily: "Plus Jakarta Sans",
  cursor: "pointer",
  margin: "0px 0px 0px 5px"
});

const AgreeBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "30px 0px 20px 0px"
});

const SetPswrdTxt = styled(Typography)({
  color: "#B6373C",
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Plus Jakarta Sans",
  textAlign: "center",
  margin: "0px 0px 5px 0px",
  "@media (max-width: 600px)": { fontSize: "16px" }
});

const MainBox = styled(Box)(({ theme }) => ({
  justifyContent: "space-around",
  display: "flex",
  margin: "40px 0px",
  gap: "20px",
  [theme.breakpoints.down("sm")]: {
    margin: "20px 20px"
  },
  [theme.breakpoints.down("xs")]: {
    alignItems: "center",
    flexDirection: "column-reverse",
    margin: "10px"
  }
}));

const FirstBox = styled(Box)(({ theme }) => ({
  width: "400px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%"
  },
  [theme.breakpoints.down("xs")]: {
    padding: "20px"
  },
}));

const InputMainBox = styled(Box)({
  margin: "0px 0px 20px 0px"
});

const InputTxt = styled(Typography)({
  color: "#334155",
  fontWeight: 600,
  fontSize: "14px",
  fontFamily: "Plus Jakarta Sans"
});

const StarTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans"
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
});

const InputField = styled(TextField)({
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});
const PswrdField = styled(TextField)({
  width: "100%",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});
const PswrdFieldBox = styled(Box)({
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 10px",
  boxSizing: "border-box",
  alignItems: "center",
  "& .eye-icon": {
    color: "#0F172A",
    cursor: "pointer"
  }
});

const PswrdImg = styled("img")({
  height: "20px",
  width: "20px"
});

const SaveButton = styled(Button)({
  width: "100%",
  backgroundColor: "#BB393D",
  color: "#fff",
  fontSize: "14px",
  textTransform: "capitalize",
  fontFamily: "Plus Jakarta Sans",
  height: "50px",
  "&.MuiButton-root:hover": {
    backgroundColor: "#BB393D"
  }
});

const BackButton = styled(Button)({
  width: "200px",
  color: "#0F172A",
  fontSize: "12px",
  textTransform: "capitalize",
  fontFamily: "Plus Jakarta Sans",
  height: "30px",
  "&.MuiButton-root:hover": {
    backgroundColor: "#fff"
  }
});

const BackBtnBox = styled(Box)({
  display: "flex",
  justifyContent: "center"
});


const SecondBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column"
});

const SideImg = styled("img")({
  height: "450px",
  width: "450px",
  "@media (max-width: 768px)": {
    height: "300px",
    width: "300px"
  },
  "@media (max-width: 600px)": {
    height: "200px",
    width: "200px"
  }
});
// Customizable Area End