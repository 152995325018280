import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconNine extends Component<IconPropsType> {
      
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M18.4414 22.55L19.9614 24.07L23.0014 21.03" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.1586 14.37C16.0586 14.36 15.9386 14.36 15.8286 14.37C13.4486 14.29 11.5586 12.34 11.5586 9.94C11.5486 7.49 13.5386 5.5 15.9886 5.5C18.4386 5.5 20.4286 7.49 20.4286 9.94C20.4286 12.34 18.5286 14.29 16.1586 14.37Z" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.9891 25.3099C14.1691 25.3099 12.3591 24.8499 10.9791 23.9299C8.55906 22.3099 8.55906 19.6699 10.9791 18.0599C13.7291 16.2199 18.2391 16.2199 20.9891 18.0599" stroke={this.props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
        )
    }
}



